import styled from "styled-components";
import team from "../../Images/Team/team1.jpg";
import vivek from "../../Images/Team/shadows/vivek.png";
import sydney from "../../Images/Team/shadows/sydney.png";
import siri from "../../Images/Team/shadows/siri.png";
import prah from "../../Images/Team/shadows/prah.png";
import mythily from "../../Images/Team/shadows/mythily.png";
import lucky from "../../Images/Team/shadows/lucky.png";
import luca from "../../Images/Team/shadows/luca.png";
import leo from "../../Images/Team/shadows/leo.png";
import hannah from "../../Images/Team/shadows/hannah.png";
import joe from "../../Images/Team/shadows/joe.png";
import aman from "../../Images/Team/shadows/aman.png";
import amory from "../../Images/Team/shadows/amory.png";
import chris from "../../Images/Team/shadows/chris.png";
import debangi from "../../Images/Team/shadows/debangi.png";

import teamf23 from "../../Images/Team/teamf23.jpg";

import React, { useState } from "react";
import NextSection from "../General/NextSection";

const SectionContainer = styled.div`
	width: 100vw;
	height: 150vh;
	background-color: #d0d1f2;
	display: flex;
	justify-content: space-around;
	align-items: center;
	align-content: center;
	position: sticky;
	top: -1px;
	max-width: 100%;
	overflow: hidden;
	flex-wrap: wrap;
`;

const TeamContainer = styled.div`
	position: relative;
	margin-bottom: 50vh;
	overflow: hidden;
	@media (max-aspect-ratio: 1233/870) {
		margin-bottom: 5vh;
		margin-top: 40vh;
	}
`;

const TeamImage = styled.img`
	width: 55vw;
	@media (max-aspect-ratio: 1233/870) {
		width: 50vw;
	}
	@media (max-aspect-ratio: 850/870) {
		width: 80vw;
	}
`;

const SvgDesktop = styled.div`
	@media (max-aspect-ratio: 1233/870) {
		display: none;
	}
	@media (max-aspect-ratio: 850/870) {
		display: none;
	}
`;

const SvgTablet = styled.div`
	display: none;
	@media (max-aspect-ratio: 1233/870) {
		display: inline-block;
	}
	@media (max-aspect-ratio: 850/870) {
		display: none;
	}
`;

const SvgPhone = styled.div`
	display: none;
	@media (max-aspect-ratio: 1233/870) {
		display: none;
	}
	@media (max-aspect-ratio: 850/870) {
		display: inline-block;
	}
`;

const ShadowContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 0.25s;
`;

const HoverContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
`;

const TextContainer = styled.div`
	width: 30vw;
	height: 80vh;
	margin-bottom: 40vh;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	flex-wrap: wrap;
	@media (max-aspect-ratio: 1233/870) {
		align-content: flex-start;
		width: 80vw;
	}
`;

const TitleText = styled.h1`
	display: inline-block;
	font-size: 6vmin;
	font-family: futura-pt, sans-serif;
	margin: 0;
	padding: 0;
	font-weight: 900;
	text-align: left;
	color: #1e1e1e;
	transition: opacity 0.5s;
`;

const BodyText = styled.p`
	display: inline-block;
	font-size: 3.2vmin;
	font-family: futura-pt, Helvetica, sans-serif;
	margin: 0;
	padding: 0;
	line-height: 1.6;
	width: 100%;
`;

const SmallSpacer = styled.div`
	width: 100%;
	height: 3vh;
`;

function TeamSection() {
	const [hoverID, setHoverID] = useState(0);
	const [hoverID1, setHoverID1] = useState(0);
	const names = [
		"YOU SHOULD NOT BE SEEING THIS",
		"Vivek Nukala",
		"Sydney Gaynor",
		"Siri Pattipati",
		"Prah Pant",
		"Mythily Lokam",
		"Ilakiya Rajaguru",
		"Luca Diomede",
		"Leo Leone",
		"Hannah Soderstrom",
		"Joe Henley",
		"Aman Shah",
		"Amory Tin",
		"Chris Zheng",
		"Debangi Mohanta",
	];
	const tv = [
		"you should not be seeing this",
		"The Good Place",
		"Little Fires Everywhere",
		"The Office",
		"Community",
		"Gilmore Girls",
		"Fabulous Lives of Bollywood Wives",
		"Stranger Things",
		"Baseball",
		"Lucifer",
		"Nathan For You",
		"Arrow",
		"Pretty Little Liars",
		"Outer Banks",
		"The Bear",
	];
	const fear = [
		"you should not be seeing this",
		"Being Buried Alive",
		"Porta-Potties",
		"Worms",
		"Spiders",
		"My Sink Drain",
		"Clowns",
		"Heights",
		"Clowns",
		"Any Flying Bug",
		"Dorm Shower Floors",
		"Heights",
		"Ghosts and Wasps",
		"Spiders",
		"Failure",
	];

	return (
		<SectionContainer>
			<NextSection ScrollNext={151} ScrollAlready={470} color="black" />
			<TeamContainer
				onMouseEnter={() => {
					setHoverID1(1);
				}}
				onMouseLeave={() => {
					setHoverID1(0);
				}}
			>
				<TitleText
					style={{
						position: "absolute",
						bottom: "5vmin",
						left: "center",
						width: "100%",
						textAlign: "center",
						color: "white",
						opacity: hoverID1 ? "0%" : "0%",
						zIndex: "999",
						fontSize: "4vmin",
					}}
				>
					______________________________
				</TitleText>
				<TeamImage src={teamf23}></TeamImage>
				<TeamImage src={teamf23}></TeamImage>
				<ShadowContainer
					style={{
						opacity: hoverID === 1 ? "0" : hoverID === 0 ? "0" : 1,
					}}
				>
					<TeamImage src={teamf23}></TeamImage>
				</ShadowContainer>
				<ShadowContainer
					style={{
						opacity: hoverID === 2 ? "0" : hoverID === 0 ? "0" : 1,
					}}
				>
					<TeamImage src={teamf23}></TeamImage>
				</ShadowContainer>
				<ShadowContainer
					style={{
						opacity: hoverID === 3 ? "0" : hoverID === 0 ? "0" : 1,
					}}
				>
					<TeamImage src={teamf23}></TeamImage>
				</ShadowContainer>
				<ShadowContainer
					style={{
						opacity: hoverID === 4 ? "0" : hoverID === 0 ? "0" : 1,
					}}
				>
					<TeamImage src={teamf23}></TeamImage>
				</ShadowContainer>
				<ShadowContainer
					style={{
						opacity: hoverID === 5 ? "0" : hoverID === 0 ? "0" : 4,
					}}
				>
					<TeamImage src={teamf23}></TeamImage>
				</ShadowContainer>
				<ShadowContainer
					style={{
						opacity: hoverID === 6 ? "0" : hoverID === 0 ? "0" : 4,
					}}
				>
					<TeamImage src={teamf23}></TeamImage>
				</ShadowContainer>
				<ShadowContainer
					style={{
						opacity: hoverID === 7 ? "0" : hoverID === 0 ? "0" : 4,
					}}
				>
					<TeamImage src={teamf23}></TeamImage>
				</ShadowContainer>
				<ShadowContainer
					style={{
						opacity: hoverID === 8 ? "0" : hoverID === 0 ? "0" : 4,
					}}
				>
					<TeamImage src={teamf23}></TeamImage>
				</ShadowContainer>
				<ShadowContainer
					style={{
						opacity: hoverID === 9 ? "0" : hoverID === 0 ? "0" : 4,
					}}
				>
					<TeamImage src={teamf23}></TeamImage>
				</ShadowContainer>
				<ShadowContainer
					style={{
						opacity: hoverID === 10 ? "0" : hoverID === 0 ? "0" : 4,
					}}
				>
					<TeamImage src={teamf23}></TeamImage>
				</ShadowContainer>
				<ShadowContainer
					style={{
						opacity: hoverID === 11 ? "0" : hoverID === 0 ? "0" : 4,
					}}
				>
					<TeamImage src={teamf23}></TeamImage>
				</ShadowContainer>
				<ShadowContainer
					style={{
						opacity: hoverID === 12 ? "0" : hoverID === 0 ? "0" : 4,
					}}
				>
					<TeamImage src={teamf23}></TeamImage>
				</ShadowContainer>
				<ShadowContainer
					style={{
						opacity: hoverID === 13 ? "0" : hoverID === 0 ? "0" : 4,
					}}
				>
					<TeamImage src={teamf23}></TeamImage>
				</ShadowContainer>
				<ShadowContainer
					style={{
						opacity: hoverID === 14 ? "0" : hoverID === 0 ? "0" : 4,
					}}
				>
					<TeamImage src={teamf23}></TeamImage>
				</ShadowContainer>
				<HoverContainer>
					<SvgDesktop>
						<svg
							version="1.0"
							xmlns="http://www.w3.org/2000/svg"
							width="55vw"
							viewBox="0 0 1200.000000 706.000000"
							preserveAspectRatio="xMidYMid meet"
						>
							<g>
								{/*vivek */}
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(1)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M4240 6318 c-30 -12 -60 -28 -66 -37 -7 -9 -26 -32 -43 -51 -38 -44
									-43 -68 -41 -214 1 -79 9 -105 50 -156 15 -18 22 -47 26 -95 6 -66 4 -72 -26
									-118 -38 -56 -51 -64 -205 -116 -65 -22 -129 -50 -142 -63 -32 -30 -46 -87
									-48 -198 0 -68 -7 -116 -24 -170 -24 -78 -48 -110 -85 -110 -11 0 -27 -4 -34
									-9 -28 -17 13 -52 131 -113 143 -73 215 -128 224 -169 3 -16 8 -29 12 -29 16
									1 43 56 51 106 14 84 17 90 71 129 28 20 62 56 77 80 22 37 41 52 112 87 74
									36 96 42 168 46 75 4 82 6 83 26 3 61 -9 126 -25 126 -20 0 -43 31 -60 80 -21
									61 -13 180 17 237 19 37 20 47 9 76 -24 65 -4 177 32 177 8 0 26 14 41 32 27
									32 27 32 22 163 -7 180 -11 194 -80 254 -45 38 -53 41 -107 41 -32 0 -64 2
									-72 4 -7 2 -38 -5 -68 -16z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(2)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M7621 6336 c-7 -8 -32 -25 -56 -36 -75 -36 -120 -90 -140 -167 -18
								-68 -50 -234 -64 -328 -4 -27 -12 -60 -19 -72 -7 -12 -11 -32 -10 -45 1 -13
								-8 -52 -20 -88 -40 -123 -46 -165 -33 -249 21 -132 10 -119 104 -124 111 -7
								143 -15 191 -49 117 -83 115 -82 142 -69 14 6 33 11 44 11 15 0 20 10 25 58 4
								31 13 98 20 147 8 50 16 135 20 189 6 112 24 163 76 218 53 56 83 72 108 56
								32 -20 43 0 26 47 -8 22 -14 59 -15 82 0 23 -4 44 -9 47 -4 3 -11 28 -15 55
								-7 55 -23 91 -74 171 -36 56 -78 99 -98 100 -6 0 -19 9 -29 20 -10 11 -26 20
								-36 20 -10 0 -21 5 -24 10 -4 6 -10 8 -14 6 -19 -12 -75 -16 -81 -6 -5 7 -11
								6 -19 -4z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(3)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M4495 5137 c-16 -5 -59 -13 -95 -18 -156 -21 -220 -44 -220 -79 0
								-12 -5 -17 -13 -14 -19 7 -33 -14 -41 -58 -5 -29 -16 -43 -49 -65 -31 -21 -46
								-41 -57 -73 -9 -25 -26 -67 -38 -93 -12 -27 -22 -57 -22 -67 0 -11 -9 -24 -19
								-30 -23 -12 -43 -67 -43 -116 0 -19 -3 -34 -8 -34 -4 0 -10 -34 -12 -77 -4
								-51 -10 -80 -20 -87 -18 -13 -38 -50 -44 -79 -2 -12 -13 -29 -23 -37 -16 -12
								-20 -27 -19 -70 1 -42 -7 -71 -31 -123 -17 -38 -38 -95 -46 -128 -9 -32 -24
								-73 -34 -91 -10 -18 -26 -60 -36 -93 -9 -33 -23 -78 -30 -100 -22 -66 -45
								-194 -45 -251 0 -34 7 -69 19 -92 19 -37 129 -142 199 -191 19 -13 42 -37 52
								-53 19 -32 16 -52 -31 -207 -17 -57 -18 -67 -5 -86 8 -12 28 -27 44 -34 l29
								-12 6 -135 7 -135 65 -132 c62 -125 66 -140 81 -252 9 -66 22 -142 30 -170 15
								-60 30 -316 24 -435 -2 -47 -4 -114 -3 -150 1 -36 2 -123 3 -195 1 -71 7 -161
								15 -200 8 -38 19 -112 25 -164 9 -67 24 -123 52 -192 l39 -98 -26 -47 c-14
								-25 -37 -63 -51 -83 -32 -47 -31 -82 4 -119 52 -55 151 -70 220 -33 34 18 41
								42 40 127 -2 108 10 205 35 294 19 69 21 104 24 435 2 198 4 374 5 390 2 47
								26 214 38 275 7 33 9 83 5 125 -18 188 -6 596 16 537 11 -28 14 -146 5 -161
								-4 -6 -3 -18 4 -26 14 -17 20 -102 26 -364 2 -112 8 -209 13 -215 11 -13 32
								-113 38 -176 2 -25 8 -182 13 -350 8 -261 12 -318 31 -395 29 -119 36 -251 15
								-312 -24 -71 -21 -110 13 -144 25 -25 37 -29 83 -29 78 0 139 33 156 84 12 36
								10 43 -16 95 -19 37 -31 79 -36 123 -6 59 -2 81 29 183 19 64 35 129 35 144 0
								21 12 38 51 71 60 51 60 57 9 188 l-33 81 22 58 c13 35 21 78 20 108 -1 28 4
								102 10 165 32 338 33 360 11 451 -15 64 -20 118 -20 226 0 157 15 248 54 332
								24 52 25 63 26 250 0 108 4 233 9 278 9 86 16 101 93 194 21 26 38 53 38 62 0
								8 7 20 16 28 26 21 54 86 54 123 0 46 -17 163 -36 246 -8 36 -14 75 -14 86 0
								36 -40 156 -64 194 -13 19 -33 64 -45 100 -12 36 -26 74 -31 85 -4 11 -14 36
								-21 55 -28 75 -62 109 -185 190 -35 23 -96 110 -105 150 -16 72 -14 168 4 188
								22 25 22 58 -3 92 -17 24 -18 30 -5 46 7 11 10 23 5 29 -11 13 -60 159 -60
								179 0 22 -47 51 -115 70 -33 10 -64 23 -70 30 -7 9 -18 10 -40 3z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(4)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M4635 5797 c-11 -7 -26 -20 -33 -29 -7 -10 -22 -18 -32 -18 -22 0
									-100 -77 -100 -99 0 -8 -8 -23 -17 -33 -11 -12 -17 -37 -17 -78 -1 -33 -5 -60
									-9 -60 -11 0 0 -101 12 -113 6 -6 11 -20 11 -31 0 -25 28 -61 54 -70 10 -3 17
									-9 14 -11 -2 -3 1 -31 7 -62 l12 -58 73 -27 c40 -15 80 -34 89 -43 14 -13 32
									-69 58 -175 3 -14 10 -28 15 -31 5 -3 4 -17 -3 -32 -10 -21 -8 -30 8 -54 34
									-48 34 -51 11 -72 -17 -16 -22 -33 -23 -73 -2 -81 15 -180 35 -208 10 -14 64
									-56 121 -95 64 -43 111 -83 123 -103 10 -18 36 -81 56 -140 38 -109 58 -132
									79 -93 6 11 11 48 11 83 -1 35 4 99 9 143 5 44 10 132 10 195 1 85 8 146 28
									236 14 66 23 131 20 145 -7 27 -122 96 -247 149 -77 32 -144 86 -161 129 -10
									24 -8 28 40 116 21 39 74 81 97 77 17 -3 40 60 43 118 1 30 4 63 7 72 3 10 -4
									38 -15 64 -12 26 -21 55 -21 65 0 10 -9 23 -20 29 -11 6 -20 15 -20 21 0 6 -9
									23 -20 39 -11 15 -17 30 -14 33 14 14 -72 76 -131 94 -50 16 -134 16 -160 0z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(5)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M7327 5272 c-13 -12 -17 -13 -17 -3 0 11 -3 11 -15 1 -8 -7 -15 -18
							-15 -26 0 -7 -19 -26 -42 -43 -24 -16 -57 -44 -75 -63 -35 -37 -103 -135 -103
							-149 0 -5 -11 -16 -24 -24 -13 -9 -32 -37 -41 -63 -19 -49 -73 -153 -84 -162
							-18 -14 -51 -96 -51 -127 0 -19 -4 -32 -9 -29 -22 14 -38 -6 -36 -45 1 -23 -3
							-43 -11 -50 -8 -6 -14 -8 -14 -5 0 13 -25 -18 -37 -45 -6 -15 -9 -36 -6 -48 4
							-15 1 -21 -10 -21 -9 0 -21 -9 -27 -20 -6 -11 -18 -20 -26 -20 -8 0 -13 -4
							-10 -9 3 -5 2 -29 -3 -54 -6 -32 -4 -54 5 -77 8 -18 14 -41 14 -51 0 -11 4
							-19 9 -19 9 0 17 -50 25 -147 2 -24 8 -48 14 -56 6 -7 20 -46 31 -87 12 -41
							25 -76 31 -78 15 -5 70 -107 70 -131 0 -11 4 -21 10 -21 5 0 7 -7 4 -15 -4
							-10 0 -15 11 -15 9 0 13 -4 10 -10 -3 -5 -2 -10 4 -10 5 0 14 7 19 16 15 26
							47 1 73 -56 12 -27 26 -50 30 -50 4 0 10 -8 13 -17 3 -10 25 -26 48 -36 25
							-10 83 -56 136 -106 89 -84 92 -89 92 -131 0 -43 -23 -121 -43 -148 -6 -7 -18
							-10 -31 -6 -12 4 -35 8 -52 10 -16 1 -52 16 -79 33 -30 19 -62 31 -82 31 -40
							0 -80 19 -108 50 -11 12 -40 36 -65 53 -69 46 -119 97 -145 147 -13 25 -42 63
							-64 85 -35 34 -41 45 -41 81 0 25 6 49 16 58 18 18 15 32 -26 118 -32 68 -37
							62 -44 -44 -4 -67 13 -177 31 -200 22 -29 105 -261 124 -349 11 -52 27 -116
							34 -144 50 -171 56 -230 55 -510 0 -148 -4 -342 -8 -430 -11 -241 -7 -262 78
							-460 73 -169 73 -171 82 -290 6 -81 21 -164 44 -255 20 -74 46 -176 59 -227
							27 -105 50 -324 40 -389 -7 -48 4 -57 25 -20 9 17 10 39 5 68 -11 66 -13 535
							-3 608 6 36 11 138 13 228 4 187 12 270 32 327 31 89 42 229 48 624 2 189 8
							318 14 324 14 14 38 -8 50 -46 15 -50 21 -314 10 -438 -15 -176 -12 -303 11
							-449 11 -74 25 -169 31 -210 5 -41 15 -95 23 -120 22 -71 35 -138 46 -235 6
							-49 15 -108 21 -130 5 -22 16 -144 24 -271 7 -127 19 -261 26 -298 9 -53 9
							-81 -1 -126 -7 -33 -10 -66 -7 -74 3 -8 -7 -33 -23 -55 -16 -22 -32 -51 -36
							-65 l-6 -26 201 0 c173 0 201 2 201 15 0 8 23 37 52 64 l52 49 -2 66 c-2 48
							-9 78 -27 109 -13 25 -27 70 -31 105 -5 51 -3 70 15 111 33 77 26 157 -22 271
							-8 19 -18 73 -22 120 -13 161 -34 279 -74 402 -89 274 -91 342 -16 667 19 80
							46 186 60 236 66 226 86 403 61 536 -14 75 -21 162 -29 349 -2 37 1 45 18 48
							30 6 75 52 96 99 11 23 31 50 46 61 l27 20 3 178 c1 99 6 258 10 354 l8 175
							24 0 c19 0 26 8 39 48 12 38 19 47 36 45 16 -2 21 4 28 35 8 39 -11 147 -31
							171 -6 7 -20 42 -31 78 -18 58 -41 95 -59 92 -13 -2 -41 22 -41 36 0 8 -25 40
							-56 71 -52 53 -94 131 -94 177 0 7 -7 20 -15 28 -8 9 -15 26 -15 38 0 13 -9
							31 -20 41 -11 10 -23 34 -26 54 -4 20 -14 47 -23 60 -9 14 -16 29 -17 35 0 6
							-2 16 -2 22 -1 7 -11 14 -23 17 -15 4 -23 0 -26 -14 -8 -31 -22 -22 -28 19
							-10 58 -24 88 -48 100 -34 16 -61 25 -69 24 -4 -1 -10 9 -14 23 -3 14 -9 23
							-13 20 -4 -2 -27 2 -52 9 -83 24 -105 26 -122 8z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(6)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M8060 5798 c0 -29 -22 -38 -51 -21 -27 16 -29 15 -69 -11 -26 -17
									-40 -33 -36 -41 3 -8 -6 -22 -20 -33 -31 -22 -54 -100 -54 -182 0 -67 -38
									-373 -48 -381 -4 -4 -24 -12 -44 -18 l-37 -12 19 -21 c11 -12 27 -46 36 -77 8
									-31 22 -66 30 -78 39 -61 58 -102 65 -138 16 -86 35 -117 132 -210 79 -76 97
									-99 106 -136 7 -24 22 -66 35 -94 37 -79 50 -148 36 -190 -10 -31 -20 -40 -42
									-36 -4 0 -10 -9 -13 -22 -18 -64 -27 -77 -55 -77 l-28 0 -7 -303 c-4 -167 -4
									-317 -1 -335 9 -36 -6 -68 -34 -77 -13 -4 -31 -28 -44 -58 -25 -56 -57 -90
									-94 -102 l-24 -7 7 -146 c3 -81 13 -178 22 -217 30 -139 8 -342 -62 -575 -14
									-47 -43 -168 -66 -270 -60 -275 -58 -359 17 -585 18 -55 37 -118 43 -140 14
									-55 41 -249 41 -298 0 -64 20 -117 47 -123 61 -16 101 30 88 100 -5 26 -2 46
									12 73 33 62 35 105 22 377 -12 260 -12 261 10 304 19 38 32 94 62 272 5 30 12
									159 15 285 6 197 4 243 -11 315 -24 121 -24 532 1 507 2 -2 12 -31 22 -63 15
									-51 17 -96 14 -334 -2 -171 1 -322 9 -400 9 -82 13 -307 13 -660 1 -486 3
									-539 19 -582 15 -40 16 -53 5 -91 -9 -35 -9 -51 2 -76 15 -37 31 -42 127 -38
									93 3 122 27 133 109 4 30 22 85 40 123 32 68 33 75 37 220 2 83 7 202 12 265
									5 66 5 248 0 425 -4 171 -9 452 -9 625 -1 277 2 328 19 419 11 57 23 106 26
									109 3 3 14 7 25 9 22 3 64 -52 56 -73 -4 -10 78 -104 91 -104 10 0 44 45 59
									78 11 25 13 42 5 65 -16 53 -25 127 -26 217 -1 100 -12 259 -25 385 -5 50 -12
									108 -14 130 -2 22 -9 63 -14 90 -5 28 -10 122 -11 210 -2 88 -8 185 -15 215
									-9 41 -10 78 -3 143 7 60 6 95 -1 110 -18 38 -50 180 -56 248 -4 43 -46 118
									-87 156 -21 20 -41 44 -44 54 -3 9 -19 22 -34 27 -27 9 -31 18 -50 104 -11 51
									-23 117 -27 145 -4 29 -10 51 -14 48 -4 -2 -10 34 -13 81 -3 57 -10 91 -20
									102 -10 11 -15 33 -14 59 2 49 -19 93 -45 93 -12 0 -18 7 -18 21 0 30 -20 61
									-35 55 -7 -2 -21 6 -29 19 -19 28 -38 40 -83 50 -25 5 -33 3 -33 -7z m262
									-410 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9 -15 0 -24z"
									/>
									<path
										d="M7648 5080 c5 -36 10 -38 21 -7 5 14 4 25 -3 29 -19 13 -23 9 -18
									-22z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(7)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M6694 6480 c-6 -6 -30 -12 -55 -14 -126 -11 -154 -30 -198 -136 -13
								-30 -30 -65 -39 -78 l-15 -23 -14 21 c-12 17 -21 20 -44 15 -22 -5 -29 -3 -29
								8 0 30 -28 41 -78 31 -42 -8 -47 -12 -50 -39 -2 -16 -8 -35 -13 -42 -27 -32
								-11 -205 21 -237 6 -6 10 -29 10 -52 0 -23 7 -58 15 -78 14 -34 14 -39 -1 -66
								-17 -28 -17 -30 7 -62 14 -18 35 -50 48 -70 l22 -37 -70 -36 c-46 -23 -75 -45
								-84 -62 -24 -46 -29 -132 -13 -203 8 -36 17 -86 21 -112 8 -59 26 -78 73 -78
								47 0 94 -28 131 -80 34 -47 51 -114 51 -203 0 -62 12 -98 27 -83 4 4 10 64 13
								132 6 141 1 132 98 165 34 11 76 32 92 45 38 33 68 114 75 211 12 147 69 348
								116 413 17 22 20 37 16 67 -6 35 -4 40 23 55 19 11 33 28 36 46 6 29 35 64 84
								101 21 16 25 28 26 74 0 31 5 62 10 69 6 7 11 37 12 67 2 46 -3 61 -33 106
								-19 29 -52 63 -72 75 -21 12 -42 29 -46 36 -5 10 -16 12 -29 8 -11 -3 -23 -2
								-27 4 -4 7 -95 52 -105 52 -1 0 -7 -5 -12 -10z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(8)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M5387 6374 c-4 -4 -18 -9 -30 -9 -12 -1 -48 -13 -79 -27 -68 -32 -76
							-51 -58 -141 7 -34 14 -127 15 -207 2 -153 10 -184 53 -224 41 -36 35 -106
							-13 -146 -17 -14 -84 -44 -183 -83 -61 -23 -67 -33 -77 -130 -6 -55 -14 -75
							-44 -114 -20 -27 -44 -54 -54 -61 -9 -7 -24 -28 -32 -45 -9 -18 -22 -43 -30
							-57 -8 -14 -15 -35 -15 -47 0 -34 64 -99 123 -125 29 -12 108 -51 176 -85 130
							-66 134 -66 147 -16 6 23 78 63 114 63 36 0 81 30 95 63 26 62 44 153 46 227
							1 41 8 111 17 155 8 44 15 95 16 113 1 17 4 32 8 32 3 0 29 23 56 52 67 69 74
							105 33 156 -26 30 -31 45 -31 89 0 42 5 57 26 80 14 15 41 56 61 90 30 55 34
							71 34 131 0 66 -23 152 -41 152 -4 0 -21 13 -36 28 -16 16 -56 37 -89 47 -33
							11 -65 23 -72 28 -17 12 -126 21 -136 11z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(9)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M6916 5814 c-56 -20 -142 -115 -162 -178 -8 -28 -19 -60 -24 -71 -10
							-25 -21 -70 -25 -110 -22 -215 -52 -317 -105 -355 -14 -9 -49 -25 -79 -35 -30
							-10 -66 -29 -79 -42 -22 -22 -23 -29 -17 -92 7 -69 -9 -220 -27 -255 -6 -12
							-7 -16 0 -12 5 3 16 -2 25 -12 12 -14 13 -27 5 -75 -10 -57 -10 -59 18 -86 45
							-44 159 -134 193 -153 19 -10 37 -32 47 -57 14 -35 19 -39 35 -31 27 15 69 80
							69 107 0 13 4 31 10 41 20 36 63 150 91 242 40 130 57 168 100 218 21 24 61
							91 90 148 58 116 98 159 195 208 49 25 54 30 54 61 0 19 -5 46 -12 62 -6 15
							-13 51 -15 78 -6 100 -8 110 -23 108 -16 -3 -30 32 -50 123 -12 52 -51 97
							-132 152 -53 36 -113 42 -182 16z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(10)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M9283 6567 c-3 -5 -11 -6 -18 -3 -13 5 -89 -28 -117 -51 -7 -6 -29
							-10 -48 -8 -19 1 -40 -4 -48 -11 -7 -8 -24 -14 -36 -14 -26 0 -84 -61 -90 -94
							-3 -12 -10 -47 -16 -79 -11 -55 -11 -59 12 -81 12 -12 30 -25 39 -28 19 -7 31
							-57 23 -97 -5 -25 -2 -32 25 -46 40 -20 51 -49 51 -134 0 -84 12 -97 84 -94
							55 2 72 -6 101 -51 l19 -29 -26 -21 c-27 -22 -27 -36 -2 -138 5 -22 -4 -43
							-48 -110 -90 -137 -151 -244 -200 -348 -17 -36 -51 -94 -77 -130 -72 -100 -74
							-108 -163 -455 l-63 -250 0 -190 c0 -177 16 -326 36 -349 4 -4 71 -9 148 -11
							78 -1 144 -4 146 -7 3 -3 1 -17 -5 -32 -5 -15 -10 -42 -10 -59 -1 -18 -7 -43
							-15 -57 -21 -36 -18 -73 8 -138 16 -38 23 -70 20 -97 -3 -22 -1 -50 4 -63 5
							-14 4 -73 -3 -145 -19 -190 -17 -277 10 -396 26 -114 44 -297 56 -586 5 -120
							15 -210 28 -270 51 -224 51 -218 5 -236 -16 -7 -77 -12 -136 -13 -103 -1 -110
							-3 -127 -26 -26 -34 -30 -54 -16 -81 16 -32 144 -66 301 -79 66 -6 163 -16
							215 -22 158 -19 279 -22 296 -7 26 22 10 179 -27 256 -16 33 -34 87 -40 120
							-5 32 -16 78 -24 103 -15 50 -28 448 -16 490 7 23 21 12 230 -195 123 -121
							250 -251 282 -288 66 -76 67 -81 23 -169 -13 -26 -24 -58 -24 -72 0 -15 -21
							-48 -61 -91 -57 -63 -60 -70 -54 -104 15 -78 46 -80 185 -10 117 59 221 151
							259 231 33 69 58 97 80 91 21 -5 51 20 134 114 33 38 45 59 42 75 -5 23 -12
							31 -101 103 -27 22 -75 66 -107 98 -98 98 -142 127 -192 127 l-43 0 -109 132
							c-60 73 -130 160 -155 193 -26 33 -74 89 -108 125 -34 36 -77 88 -97 117 -19
							29 -56 68 -82 88 -69 54 -74 70 -42 119 23 36 26 51 27 131 0 50 5 100 12 112
							7 12 12 48 12 81 0 52 4 65 38 113 80 114 115 154 142 166 33 14 35 24 46 173
							8 115 7 129 -8 300 -13 138 -15 301 -10 725 3 228 -10 458 -28 480 -4 5 -11
							33 -15 60 -4 28 -13 64 -20 80 -7 17 -24 55 -38 87 -14 31 -43 78 -64 106 -22
							27 -46 72 -53 98 -15 57 -26 73 -56 84 -15 6 -26 26 -38 71 -10 34 -14 64 -9
							68 17 12 63 117 63 143 0 15 6 33 13 40 8 7 18 27 24 43 5 17 12 28 14 25 6
							-7 49 33 49 47 0 40 -111 168 -137 158 -7 -2 -13 0 -13 6 0 5 -10 13 -23 18
							-13 5 -37 21 -54 35 -16 14 -33 23 -36 19 -4 -3 -17 2 -31 10 -26 17 -113 20
							-123 4z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(11)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M3317 5700 c-14 -16 -32 -30 -39 -30 -22 0 -115 -102 -133 -145 -4
								-11 -7 -36 -6 -56 2 -26 -5 -44 -25 -68 l-27 -32 20 -22 c38 -41 88 -170 98
								-250 9 -68 -3 -91 -67 -135 -69 -46 -192 -109 -230 -116 -50 -9 -113 -54 -137
								-96 -14 -25 -21 -56 -21 -88 0 -28 -4 -54 -10 -57 -5 -3 -10 -17 -10 -30 0
								-13 -8 -43 -18 -67 -12 -27 -24 -95 -32 -184 -11 -127 -11 -149 5 -219 19 -87
								16 -152 -11 -238 l-18 -58 27 -79 c15 -44 31 -119 37 -167 5 -49 16 -113 25
								-143 8 -30 15 -71 15 -90 0 -49 13 -65 50 -62 29 3 30 2 30 -37 0 -22 5 -43
								11 -47 7 -4 8 -36 4 -99 -5 -58 -3 -126 5 -178 9 -67 9 -101 0 -159 -7 -40
								-14 -125 -15 -188 -5 -208 -15 -338 -30 -402 -12 -55 -24 -135 -40 -283 -3
								-22 -11 -96 -19 -165 -8 -69 -17 -205 -20 -304 -5 -175 -5 -178 17 -197 12
								-10 28 -18 35 -19 15 0 16 -44 1 -73 -14 -26 -13 -281 1 -307 18 -34 52 -39
								179 -30 133 10 151 36 117 170 -39 154 -39 225 -1 530 18 146 27 390 16 445
								-9 46 -7 56 26 130 19 44 45 127 58 185 56 260 117 510 137 568 40 114 48 87
								59 -208 4 -129 13 -287 19 -350 7 -70 8 -173 3 -265 -4 -82 -6 -156 -4 -163 1
								-7 -5 -51 -14 -97 -9 -47 -18 -166 -21 -271 -4 -163 -7 -191 -26 -227 -18 -35
								-21 -57 -19 -147 1 -57 5 -108 10 -113 4 -4 35 -13 68 -20 33 -7 78 -25 100
								-40 52 -36 127 -57 225 -62 103 -6 128 4 124 47 -5 53 -15 69 -101 153 -45 44
								-88 96 -97 115 -15 33 -14 42 7 145 12 61 30 198 39 305 30 332 63 581 111
								835 15 77 29 157 32 177 7 45 -3 88 -20 88 -6 0 -20 7 -31 15 -16 12 -17 18
								-6 47 7 18 16 51 20 73 5 22 14 58 20 80 26 91 27 89 -32 134 -238 184 -265
								233 -228 402 11 52 27 112 35 134 8 22 19 66 25 97 6 31 23 74 36 94 14 20 34
								73 45 117 11 44 33 106 49 136 15 31 31 71 35 90 8 40 59 144 90 186 61 82 97
								208 92 320 -4 104 -28 129 -203 221 -175 92 -199 116 -129 130 42 8 70 40 80
								91 4 21 14 51 22 67 11 21 13 44 9 86 -6 47 -3 63 17 101 14 26 27 71 31 111
								6 60 4 70 -19 105 -27 42 -27 42 -70 77 -29 24 -29 25 -7 26 12 0 22 4 22 9 0
								5 -19 9 -42 9 -55 0 -117 7 -125 16 -10 9 -63 7 -106 -5 -48 -13 -75 -4 -83
								27 -8 31 -17 30 -47 -6z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(12)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M6062 5202 c3 -10 0 -26 -7 -36 -12 -16 -92 -51 -142 -63 -42 -10
							-104 -91 -137 -182 -22 -61 -38 -89 -54 -96 -15 -6 -24 -21 -28 -45 -3 -19 -9
							-43 -15 -52 -5 -10 -9 -33 -9 -51 0 -18 -7 -58 -16 -87 -18 -61 -13 -129 11
							-166 24 -38 18 -59 -22 -78 -60 -29 -143 -87 -156 -110 -8 -14 -12 -82 -13
							-216 0 -128 -6 -234 -17 -310 -13 -88 -15 -127 -7 -168 8 -44 7 -59 -6 -84
							-14 -27 -15 -59 -11 -212 5 -155 9 -193 31 -269 32 -108 68 -194 112 -270 19
							-32 37 -70 41 -85 3 -15 15 -40 25 -57 10 -16 18 -38 18 -47 0 -9 13 -25 30
							-34 16 -10 32 -24 34 -31 3 -7 1 -93 -4 -191 -6 -97 -8 -220 -5 -272 10 -175
							14 -342 20 -755 11 -878 17 -1005 48 -1052 4 -7 3 -22 -4 -34 -14 -28 -7 -88
							16 -123 17 -25 20 -26 121 -26 117 0 124 4 124 80 0 29 13 68 40 123 42 86 42
							86 41 266 -1 40 6 105 14 145 8 39 17 88 20 109 4 21 15 63 26 94 28 83 40
							159 49 333 11 192 33 403 59 580 12 74 21 152 21 173 0 73 47 26 60 -59 4 -33
							19 -102 33 -154 13 -52 28 -117 31 -145 4 -27 16 -84 27 -125 11 -41 27 -111
							34 -156 8 -44 26 -105 39 -135 14 -30 27 -76 31 -104 11 -82 68 -244 100 -285
							12 -15 43 -182 43 -230 0 -14 2 -65 5 -115 6 -100 -5 -173 -32 -204 -10 -12
							-24 -41 -31 -66 -10 -37 -10 -52 2 -83 l14 -37 122 -3 121 -3 26 50 c18 37 25
							66 25 111 0 56 2 61 26 70 14 6 38 10 53 10 31 0 61 27 61 56 0 10 10 34 21
							53 20 33 20 40 9 130 -6 53 -18 114 -26 136 -8 23 -14 50 -14 61 0 11 -17 78
							-39 149 -21 72 -44 157 -52 190 -7 33 -16 65 -21 71 -4 6 -12 71 -17 145 -9
							129 -11 138 -61 259 -110 267 -103 240 -104 395 -1 77 3 286 8 465 9 332 8
							344 -30 500 -27 114 -31 133 -54 235 -12 55 -38 138 -57 185 -19 47 -46 111
							-59 144 -14 32 -22 61 -18 65 4 3 9 -1 12 -9 3 -8 22 -31 42 -50 21 -19 54
							-64 75 -98 24 -41 53 -75 83 -95 25 -18 72 -56 105 -84 40 -36 71 -54 95 -58
							21 -3 69 -21 107 -40 47 -22 88 -35 125 -37 l55 -3 22 60 c38 104 29 147 -46
							210 -19 17 -51 46 -71 66 -19 20 -56 48 -81 62 -53 30 -99 80 -99 107 0 24
							-56 77 -89 83 -20 4 -33 17 -46 44 -10 21 -33 63 -51 94 -56 94 -90 214 -108
							392 -20 189 -35 237 -77 237 -15 0 -34 7 -41 16 -7 9 -32 37 -56 61 -25 26
							-41 51 -38 59 3 9 -2 14 -14 14 -29 0 -33 1 -54 16 -16 11 -19 28 -21 126 -1
							62 -6 122 -12 133 -6 11 -11 58 -12 105 -2 96 -23 155 -73 207 -31 32 -49 39
							-118 48 -19 2 -40 11 -46 20 -9 12 -13 13 -16 3 -3 -9 -15 -2 -34 20 -32 36
							-49 41 -42 14z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(13)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M3139 6282 c-10 -2 -19 -11 -22 -21 -2 -11 -22 -23 -51 -31 -26 -7
							-46 -19 -46 -26 0 -26 -1 -29 -26 -61 -49 -64 -59 -102 -59 -215 1 -117 13
							-155 52 -164 35 -9 53 -42 53 -96 0 -71 -24 -112 -94 -157 -56 -35 -199 -85
							-306 -106 -19 -3 -42 -8 -51 -11 -17 -5 -33 -49 -44 -124 -9 -54 -39 -174 -54
							-213 -6 -16 -11 -39 -11 -53 0 -13 -11 -70 -24 -127 -61 -261 -64 -549 -6
							-657 7 -14 9 -40 5 -71 -7 -46 8 -141 25 -164 4 -5 15 -31 24 -56 13 -33 28
							-53 56 -70 24 -15 41 -34 45 -51 4 -15 12 -31 19 -37 8 -6 13 -48 15 -120 7
							-283 22 -603 31 -666 8 -50 8 -133 -1 -295 -11 -214 -8 -589 7 -772 7 -77 7
							-78 40 -90 19 -7 34 -18 34 -25 0 -7 4 -13 9 -13 4 0 11 33 15 73 7 88 23 197
							41 297 20 107 38 367 30 421 -4 27 -2 71 6 105 6 33 11 97 9 144 -2 47 -3 147
							-3 223 0 83 -4 137 -10 137 -6 0 -7 17 -4 41 5 38 3 42 -23 52 -18 7 -35 24
							-44 46 -22 49 -56 187 -56 226 0 17 -9 74 -20 125 -11 51 -20 95 -20 96 0 1
							-8 10 -17 20 -16 16 -16 21 9 93 24 70 25 82 16 151 -18 141 -20 292 -5 334
							12 36 12 41 -5 58 -17 17 -17 20 -3 35 26 26 33 54 25 111 -3 29 -3 55 0 57 4
							3 13 25 20 50 8 28 16 42 20 34 5 -8 10 -5 14 9 12 37 93 99 146 112 26 6 81
							30 122 53 41 23 87 49 102 57 37 19 136 118 136 135 0 8 -16 30 -35 50 -18 20
							-42 58 -51 83 -9 26 -23 63 -32 82 -9 19 -15 51 -14 70 1 19 4 66 6 103 2 55
							7 73 28 95 14 15 34 41 46 58 12 17 37 39 57 48 19 9 51 36 71 61 33 39 40 43
							69 38 48 -8 59 19 52 121 -8 114 -19 186 -27 186 -5 0 -13 12 -19 28 -20 49
							-33 66 -98 123 -13 10 -23 16 -23 13 0 -3 -17 4 -37 16 -24 14 -46 20 -64 16
							-15 -3 -29 -2 -31 2 -2 4 -10 6 -19 4z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(14)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M5787 5654 c-2 -3 -22 -5 -43 -6 -32 -1 -47 -9 -80 -43 -23 -23 -45
							-51 -48 -63 -4 -12 -14 -22 -23 -22 -8 0 -13 -6 -10 -12 2 -7 -2 -28 -9 -47
							-8 -18 -14 -44 -14 -58 0 -13 -6 -26 -14 -29 -11 -4 -13 -12 -6 -28 5 -15 2
							-34 -11 -62 l-18 -39 -2 30 c-2 41 -16 -59 -22 -160 -3 -44 -5 -84 -6 -88 -1
							-5 -9 -6 -20 -2 -13 4 -24 -4 -43 -32 -13 -21 -27 -46 -31 -55 -5 -14 -13 -16
							-38 -11 -28 5 -31 4 -26 -11 4 -11 1 -19 -10 -23 -9 -4 -19 -1 -21 5 -2 7 -16
							-6 -32 -29 -25 -38 -27 -45 -15 -73 11 -26 10 -45 -7 -121 -19 -89 -31 -200
							-42 -403 -3 -57 -9 -114 -12 -128 -6 -24 16 -71 42 -87 7 -4 15 -26 19 -50 9
							-55 28 -90 58 -110 23 -15 26 -14 49 11 37 39 48 65 48 114 0 46 36 128 70
							160 11 10 22 29 26 42 6 27 74 76 173 125 34 18 64 38 67 46 3 7 -2 25 -12 40
							-15 23 -16 36 -6 113 6 48 14 119 17 157 3 42 19 108 40 167 43 123 50 136 93
							175 63 56 123 82 204 89 l74 7 -13 89 c-8 49 -20 106 -28 125 -8 20 -15 50
							-16 67 -1 44 -11 65 -39 79 -16 8 -26 23 -28 41 -2 18 -11 30 -22 33 -11 3
							-22 13 -25 22 -7 21 -63 53 -71 40 -4 -7 -12 -5 -22 4 -15 14 -84 21 -95 11z"
									/>
								</g>
							</g>
						</svg>
					</SvgDesktop>

					<SvgTablet>
						<svg
							version="1.0"
							xmlns="http://www.w3.org/2000/svg"
							width="50vw"
							viewBox="0 0 1911.000000 1147.000000"
							preserveAspectRatio="xMidYMid meet"
						>
							<g>
								{/*vivek */}
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(1)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M4240 6318 c-30 -12 -60 -28 -66 -37 -7 -9 -26 -32 -43 -51 -38 -44
							-43 -68 -41 -214 1 -79 9 -105 50 -156 15 -18 22 -47 26 -95 6 -66 4 -72 -26
							-118 -38 -56 -51 -64 -205 -116 -65 -22 -129 -50 -142 -63 -32 -30 -46 -87
							-48 -198 0 -68 -7 -116 -24 -170 -24 -78 -48 -110 -85 -110 -11 0 -27 -4 -34
							-9 -28 -17 13 -52 131 -113 143 -73 215 -128 224 -169 3 -16 8 -29 12 -29 16
							1 43 56 51 106 14 84 17 90 71 129 28 20 62 56 77 80 22 37 41 52 112 87 74
							36 96 42 168 46 75 4 82 6 83 26 3 61 -9 126 -25 126 -20 0 -43 31 -60 80 -21
							61 -13 180 17 237 19 37 20 47 9 76 -24 65 -4 177 32 177 8 0 26 14 41 32 27
							32 27 32 22 163 -7 180 -11 194 -80 254 -45 38 -53 41 -107 41 -32 0 -64 2
							-72 4 -7 2 -38 -5 -68 -16z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(2)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M7621 6336 c-7 -8 -32 -25 -56 -36 -75 -36 -120 -90 -140 -167 -18
						-68 -50 -234 -64 -328 -4 -27 -12 -60 -19 -72 -7 -12 -11 -32 -10 -45 1 -13
						-8 -52 -20 -88 -40 -123 -46 -165 -33 -249 21 -132 10 -119 104 -124 111 -7
						143 -15 191 -49 117 -83 115 -82 142 -69 14 6 33 11 44 11 15 0 20 10 25 58 4
						31 13 98 20 147 8 50 16 135 20 189 6 112 24 163 76 218 53 56 83 72 108 56
						32 -20 43 0 26 47 -8 22 -14 59 -15 82 0 23 -4 44 -9 47 -4 3 -11 28 -15 55
						-7 55 -23 91 -74 171 -36 56 -78 99 -98 100 -6 0 -19 9 -29 20 -10 11 -26 20
						-36 20 -10 0 -21 5 -24 10 -4 6 -10 8 -14 6 -19 -12 -75 -16 -81 -6 -5 7 -11
						6 -19 -4z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(3)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M4495 5137 c-16 -5 -59 -13 -95 -18 -156 -21 -220 -44 -220 -79 0
						-12 -5 -17 -13 -14 -19 7 -33 -14 -41 -58 -5 -29 -16 -43 -49 -65 -31 -21 -46
						-41 -57 -73 -9 -25 -26 -67 -38 -93 -12 -27 -22 -57 -22 -67 0 -11 -9 -24 -19
						-30 -23 -12 -43 -67 -43 -116 0 -19 -3 -34 -8 -34 -4 0 -10 -34 -12 -77 -4
						-51 -10 -80 -20 -87 -18 -13 -38 -50 -44 -79 -2 -12 -13 -29 -23 -37 -16 -12
						-20 -27 -19 -70 1 -42 -7 -71 -31 -123 -17 -38 -38 -95 -46 -128 -9 -32 -24
						-73 -34 -91 -10 -18 -26 -60 -36 -93 -9 -33 -23 -78 -30 -100 -22 -66 -45
						-194 -45 -251 0 -34 7 -69 19 -92 19 -37 129 -142 199 -191 19 -13 42 -37 52
						-53 19 -32 16 -52 -31 -207 -17 -57 -18 -67 -5 -86 8 -12 28 -27 44 -34 l29
						-12 6 -135 7 -135 65 -132 c62 -125 66 -140 81 -252 9 -66 22 -142 30 -170 15
						-60 30 -316 24 -435 -2 -47 -4 -114 -3 -150 1 -36 2 -123 3 -195 1 -71 7 -161
						15 -200 8 -38 19 -112 25 -164 9 -67 24 -123 52 -192 l39 -98 -26 -47 c-14
						-25 -37 -63 -51 -83 -32 -47 -31 -82 4 -119 52 -55 151 -70 220 -33 34 18 41
						42 40 127 -2 108 10 205 35 294 19 69 21 104 24 435 2 198 4 374 5 390 2 47
						26 214 38 275 7 33 9 83 5 125 -18 188 -6 596 16 537 11 -28 14 -146 5 -161
						-4 -6 -3 -18 4 -26 14 -17 20 -102 26 -364 2 -112 8 -209 13 -215 11 -13 32
						-113 38 -176 2 -25 8 -182 13 -350 8 -261 12 -318 31 -395 29 -119 36 -251 15
						-312 -24 -71 -21 -110 13 -144 25 -25 37 -29 83 -29 78 0 139 33 156 84 12 36
						10 43 -16 95 -19 37 -31 79 -36 123 -6 59 -2 81 29 183 19 64 35 129 35 144 0
						21 12 38 51 71 60 51 60 57 9 188 l-33 81 22 58 c13 35 21 78 20 108 -1 28 4
						102 10 165 32 338 33 360 11 451 -15 64 -20 118 -20 226 0 157 15 248 54 332
						24 52 25 63 26 250 0 108 4 233 9 278 9 86 16 101 93 194 21 26 38 53 38 62 0
						8 7 20 16 28 26 21 54 86 54 123 0 46 -17 163 -36 246 -8 36 -14 75 -14 86 0
						36 -40 156 -64 194 -13 19 -33 64 -45 100 -12 36 -26 74 -31 85 -4 11 -14 36
						-21 55 -28 75 -62 109 -185 190 -35 23 -96 110 -105 150 -16 72 -14 168 4 188
						22 25 22 58 -3 92 -17 24 -18 30 -5 46 7 11 10 23 5 29 -11 13 -60 159 -60
						179 0 22 -47 51 -115 70 -33 10 -64 23 -70 30 -7 9 -18 10 -40 3z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(4)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M4635 5797 c-11 -7 -26 -20 -33 -29 -7 -10 -22 -18 -32 -18 -22 0
							-100 -77 -100 -99 0 -8 -8 -23 -17 -33 -11 -12 -17 -37 -17 -78 -1 -33 -5 -60
							-9 -60 -11 0 0 -101 12 -113 6 -6 11 -20 11 -31 0 -25 28 -61 54 -70 10 -3 17
							-9 14 -11 -2 -3 1 -31 7 -62 l12 -58 73 -27 c40 -15 80 -34 89 -43 14 -13 32
							-69 58 -175 3 -14 10 -28 15 -31 5 -3 4 -17 -3 -32 -10 -21 -8 -30 8 -54 34
							-48 34 -51 11 -72 -17 -16 -22 -33 -23 -73 -2 -81 15 -180 35 -208 10 -14 64
							-56 121 -95 64 -43 111 -83 123 -103 10 -18 36 -81 56 -140 38 -109 58 -132
							79 -93 6 11 11 48 11 83 -1 35 4 99 9 143 5 44 10 132 10 195 1 85 8 146 28
							236 14 66 23 131 20 145 -7 27 -122 96 -247 149 -77 32 -144 86 -161 129 -10
							24 -8 28 40 116 21 39 74 81 97 77 17 -3 40 60 43 118 1 30 4 63 7 72 3 10 -4
							38 -15 64 -12 26 -21 55 -21 65 0 10 -9 23 -20 29 -11 6 -20 15 -20 21 0 6 -9
							23 -20 39 -11 15 -17 30 -14 33 14 14 -72 76 -131 94 -50 16 -134 16 -160 0z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(5)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M7327 5272 c-13 -12 -17 -13 -17 -3 0 11 -3 11 -15 1 -8 -7 -15 -18
					-15 -26 0 -7 -19 -26 -42 -43 -24 -16 -57 -44 -75 -63 -35 -37 -103 -135 -103
					-149 0 -5 -11 -16 -24 -24 -13 -9 -32 -37 -41 -63 -19 -49 -73 -153 -84 -162
					-18 -14 -51 -96 -51 -127 0 -19 -4 -32 -9 -29 -22 14 -38 -6 -36 -45 1 -23 -3
					-43 -11 -50 -8 -6 -14 -8 -14 -5 0 13 -25 -18 -37 -45 -6 -15 -9 -36 -6 -48 4
					-15 1 -21 -10 -21 -9 0 -21 -9 -27 -20 -6 -11 -18 -20 -26 -20 -8 0 -13 -4
					-10 -9 3 -5 2 -29 -3 -54 -6 -32 -4 -54 5 -77 8 -18 14 -41 14 -51 0 -11 4
					-19 9 -19 9 0 17 -50 25 -147 2 -24 8 -48 14 -56 6 -7 20 -46 31 -87 12 -41
					25 -76 31 -78 15 -5 70 -107 70 -131 0 -11 4 -21 10 -21 5 0 7 -7 4 -15 -4
					-10 0 -15 11 -15 9 0 13 -4 10 -10 -3 -5 -2 -10 4 -10 5 0 14 7 19 16 15 26
					47 1 73 -56 12 -27 26 -50 30 -50 4 0 10 -8 13 -17 3 -10 25 -26 48 -36 25
					-10 83 -56 136 -106 89 -84 92 -89 92 -131 0 -43 -23 -121 -43 -148 -6 -7 -18
					-10 -31 -6 -12 4 -35 8 -52 10 -16 1 -52 16 -79 33 -30 19 -62 31 -82 31 -40
					0 -80 19 -108 50 -11 12 -40 36 -65 53 -69 46 -119 97 -145 147 -13 25 -42 63
					-64 85 -35 34 -41 45 -41 81 0 25 6 49 16 58 18 18 15 32 -26 118 -32 68 -37
					62 -44 -44 -4 -67 13 -177 31 -200 22 -29 105 -261 124 -349 11 -52 27 -116
					34 -144 50 -171 56 -230 55 -510 0 -148 -4 -342 -8 -430 -11 -241 -7 -262 78
					-460 73 -169 73 -171 82 -290 6 -81 21 -164 44 -255 20 -74 46 -176 59 -227
					27 -105 50 -324 40 -389 -7 -48 4 -57 25 -20 9 17 10 39 5 68 -11 66 -13 535
					-3 608 6 36 11 138 13 228 4 187 12 270 32 327 31 89 42 229 48 624 2 189 8
					318 14 324 14 14 38 -8 50 -46 15 -50 21 -314 10 -438 -15 -176 -12 -303 11
					-449 11 -74 25 -169 31 -210 5 -41 15 -95 23 -120 22 -71 35 -138 46 -235 6
					-49 15 -108 21 -130 5 -22 16 -144 24 -271 7 -127 19 -261 26 -298 9 -53 9
					-81 -1 -126 -7 -33 -10 -66 -7 -74 3 -8 -7 -33 -23 -55 -16 -22 -32 -51 -36
					-65 l-6 -26 201 0 c173 0 201 2 201 15 0 8 23 37 52 64 l52 49 -2 66 c-2 48
					-9 78 -27 109 -13 25 -27 70 -31 105 -5 51 -3 70 15 111 33 77 26 157 -22 271
					-8 19 -18 73 -22 120 -13 161 -34 279 -74 402 -89 274 -91 342 -16 667 19 80
					46 186 60 236 66 226 86 403 61 536 -14 75 -21 162 -29 349 -2 37 1 45 18 48
					30 6 75 52 96 99 11 23 31 50 46 61 l27 20 3 178 c1 99 6 258 10 354 l8 175
					24 0 c19 0 26 8 39 48 12 38 19 47 36 45 16 -2 21 4 28 35 8 39 -11 147 -31
					171 -6 7 -20 42 -31 78 -18 58 -41 95 -59 92 -13 -2 -41 22 -41 36 0 8 -25 40
					-56 71 -52 53 -94 131 -94 177 0 7 -7 20 -15 28 -8 9 -15 26 -15 38 0 13 -9
					31 -20 41 -11 10 -23 34 -26 54 -4 20 -14 47 -23 60 -9 14 -16 29 -17 35 0 6
					-2 16 -2 22 -1 7 -11 14 -23 17 -15 4 -23 0 -26 -14 -8 -31 -22 -22 -28 19
					-10 58 -24 88 -48 100 -34 16 -61 25 -69 24 -4 -1 -10 9 -14 23 -3 14 -9 23
					-13 20 -4 -2 -27 2 -52 9 -83 24 -105 26 -122 8z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(6)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M8060 5798 c0 -29 -22 -38 -51 -21 -27 16 -29 15 -69 -11 -26 -17
							-40 -33 -36 -41 3 -8 -6 -22 -20 -33 -31 -22 -54 -100 -54 -182 0 -67 -38
							-373 -48 -381 -4 -4 -24 -12 -44 -18 l-37 -12 19 -21 c11 -12 27 -46 36 -77 8
							-31 22 -66 30 -78 39 -61 58 -102 65 -138 16 -86 35 -117 132 -210 79 -76 97
							-99 106 -136 7 -24 22 -66 35 -94 37 -79 50 -148 36 -190 -10 -31 -20 -40 -42
							-36 -4 0 -10 -9 -13 -22 -18 -64 -27 -77 -55 -77 l-28 0 -7 -303 c-4 -167 -4
							-317 -1 -335 9 -36 -6 -68 -34 -77 -13 -4 -31 -28 -44 -58 -25 -56 -57 -90
							-94 -102 l-24 -7 7 -146 c3 -81 13 -178 22 -217 30 -139 8 -342 -62 -575 -14
							-47 -43 -168 -66 -270 -60 -275 -58 -359 17 -585 18 -55 37 -118 43 -140 14
							-55 41 -249 41 -298 0 -64 20 -117 47 -123 61 -16 101 30 88 100 -5 26 -2 46
							12 73 33 62 35 105 22 377 -12 260 -12 261 10 304 19 38 32 94 62 272 5 30 12
							159 15 285 6 197 4 243 -11 315 -24 121 -24 532 1 507 2 -2 12 -31 22 -63 15
							-51 17 -96 14 -334 -2 -171 1 -322 9 -400 9 -82 13 -307 13 -660 1 -486 3
							-539 19 -582 15 -40 16 -53 5 -91 -9 -35 -9 -51 2 -76 15 -37 31 -42 127 -38
							93 3 122 27 133 109 4 30 22 85 40 123 32 68 33 75 37 220 2 83 7 202 12 265
							5 66 5 248 0 425 -4 171 -9 452 -9 625 -1 277 2 328 19 419 11 57 23 106 26
							109 3 3 14 7 25 9 22 3 64 -52 56 -73 -4 -10 78 -104 91 -104 10 0 44 45 59
							78 11 25 13 42 5 65 -16 53 -25 127 -26 217 -1 100 -12 259 -25 385 -5 50 -12
							108 -14 130 -2 22 -9 63 -14 90 -5 28 -10 122 -11 210 -2 88 -8 185 -15 215
							-9 41 -10 78 -3 143 7 60 6 95 -1 110 -18 38 -50 180 -56 248 -4 43 -46 118
							-87 156 -21 20 -41 44 -44 54 -3 9 -19 22 -34 27 -27 9 -31 18 -50 104 -11 51
							-23 117 -27 145 -4 29 -10 51 -14 48 -4 -2 -10 34 -13 81 -3 57 -10 91 -20
							102 -10 11 -15 33 -14 59 2 49 -19 93 -45 93 -12 0 -18 7 -18 21 0 30 -20 61
							-35 55 -7 -2 -21 6 -29 19 -19 28 -38 40 -83 50 -25 5 -33 3 -33 -7z m262
							-410 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9 -15 0 -24z"
									/>
									<path
										d="M7648 5080 c5 -36 10 -38 21 -7 5 14 4 25 -3 29 -19 13 -23 9 -18
							-22z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(7)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M6694 6480 c-6 -6 -30 -12 -55 -14 -126 -11 -154 -30 -198 -136 -13
						-30 -30 -65 -39 -78 l-15 -23 -14 21 c-12 17 -21 20 -44 15 -22 -5 -29 -3 -29
						8 0 30 -28 41 -78 31 -42 -8 -47 -12 -50 -39 -2 -16 -8 -35 -13 -42 -27 -32
						-11 -205 21 -237 6 -6 10 -29 10 -52 0 -23 7 -58 15 -78 14 -34 14 -39 -1 -66
						-17 -28 -17 -30 7 -62 14 -18 35 -50 48 -70 l22 -37 -70 -36 c-46 -23 -75 -45
						-84 -62 -24 -46 -29 -132 -13 -203 8 -36 17 -86 21 -112 8 -59 26 -78 73 -78
						47 0 94 -28 131 -80 34 -47 51 -114 51 -203 0 -62 12 -98 27 -83 4 4 10 64 13
						132 6 141 1 132 98 165 34 11 76 32 92 45 38 33 68 114 75 211 12 147 69 348
						116 413 17 22 20 37 16 67 -6 35 -4 40 23 55 19 11 33 28 36 46 6 29 35 64 84
						101 21 16 25 28 26 74 0 31 5 62 10 69 6 7 11 37 12 67 2 46 -3 61 -33 106
						-19 29 -52 63 -72 75 -21 12 -42 29 -46 36 -5 10 -16 12 -29 8 -11 -3 -23 -2
						-27 4 -4 7 -95 52 -105 52 -1 0 -7 -5 -12 -10z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(8)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M5387 6374 c-4 -4 -18 -9 -30 -9 -12 -1 -48 -13 -79 -27 -68 -32 -76
					-51 -58 -141 7 -34 14 -127 15 -207 2 -153 10 -184 53 -224 41 -36 35 -106
					-13 -146 -17 -14 -84 -44 -183 -83 -61 -23 -67 -33 -77 -130 -6 -55 -14 -75
					-44 -114 -20 -27 -44 -54 -54 -61 -9 -7 -24 -28 -32 -45 -9 -18 -22 -43 -30
					-57 -8 -14 -15 -35 -15 -47 0 -34 64 -99 123 -125 29 -12 108 -51 176 -85 130
					-66 134 -66 147 -16 6 23 78 63 114 63 36 0 81 30 95 63 26 62 44 153 46 227
					1 41 8 111 17 155 8 44 15 95 16 113 1 17 4 32 8 32 3 0 29 23 56 52 67 69 74
					105 33 156 -26 30 -31 45 -31 89 0 42 5 57 26 80 14 15 41 56 61 90 30 55 34
					71 34 131 0 66 -23 152 -41 152 -4 0 -21 13 -36 28 -16 16 -56 37 -89 47 -33
					11 -65 23 -72 28 -17 12 -126 21 -136 11z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(9)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M6916 5814 c-56 -20 -142 -115 -162 -178 -8 -28 -19 -60 -24 -71 -10
					-25 -21 -70 -25 -110 -22 -215 -52 -317 -105 -355 -14 -9 -49 -25 -79 -35 -30
					-10 -66 -29 -79 -42 -22 -22 -23 -29 -17 -92 7 -69 -9 -220 -27 -255 -6 -12
					-7 -16 0 -12 5 3 16 -2 25 -12 12 -14 13 -27 5 -75 -10 -57 -10 -59 18 -86 45
					-44 159 -134 193 -153 19 -10 37 -32 47 -57 14 -35 19 -39 35 -31 27 15 69 80
					69 107 0 13 4 31 10 41 20 36 63 150 91 242 40 130 57 168 100 218 21 24 61
					91 90 148 58 116 98 159 195 208 49 25 54 30 54 61 0 19 -5 46 -12 62 -6 15
					-13 51 -15 78 -6 100 -8 110 -23 108 -16 -3 -30 32 -50 123 -12 52 -51 97
					-132 152 -53 36 -113 42 -182 16z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(10)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M9283 6567 c-3 -5 -11 -6 -18 -3 -13 5 -89 -28 -117 -51 -7 -6 -29
					-10 -48 -8 -19 1 -40 -4 -48 -11 -7 -8 -24 -14 -36 -14 -26 0 -84 -61 -90 -94
					-3 -12 -10 -47 -16 -79 -11 -55 -11 -59 12 -81 12 -12 30 -25 39 -28 19 -7 31
					-57 23 -97 -5 -25 -2 -32 25 -46 40 -20 51 -49 51 -134 0 -84 12 -97 84 -94
					55 2 72 -6 101 -51 l19 -29 -26 -21 c-27 -22 -27 -36 -2 -138 5 -22 -4 -43
					-48 -110 -90 -137 -151 -244 -200 -348 -17 -36 -51 -94 -77 -130 -72 -100 -74
					-108 -163 -455 l-63 -250 0 -190 c0 -177 16 -326 36 -349 4 -4 71 -9 148 -11
					78 -1 144 -4 146 -7 3 -3 1 -17 -5 -32 -5 -15 -10 -42 -10 -59 -1 -18 -7 -43
					-15 -57 -21 -36 -18 -73 8 -138 16 -38 23 -70 20 -97 -3 -22 -1 -50 4 -63 5
					-14 4 -73 -3 -145 -19 -190 -17 -277 10 -396 26 -114 44 -297 56 -586 5 -120
					15 -210 28 -270 51 -224 51 -218 5 -236 -16 -7 -77 -12 -136 -13 -103 -1 -110
					-3 -127 -26 -26 -34 -30 -54 -16 -81 16 -32 144 -66 301 -79 66 -6 163 -16
					215 -22 158 -19 279 -22 296 -7 26 22 10 179 -27 256 -16 33 -34 87 -40 120
					-5 32 -16 78 -24 103 -15 50 -28 448 -16 490 7 23 21 12 230 -195 123 -121
					250 -251 282 -288 66 -76 67 -81 23 -169 -13 -26 -24 -58 -24 -72 0 -15 -21
					-48 -61 -91 -57 -63 -60 -70 -54 -104 15 -78 46 -80 185 -10 117 59 221 151
					259 231 33 69 58 97 80 91 21 -5 51 20 134 114 33 38 45 59 42 75 -5 23 -12
					31 -101 103 -27 22 -75 66 -107 98 -98 98 -142 127 -192 127 l-43 0 -109 132
					c-60 73 -130 160 -155 193 -26 33 -74 89 -108 125 -34 36 -77 88 -97 117 -19
					29 -56 68 -82 88 -69 54 -74 70 -42 119 23 36 26 51 27 131 0 50 5 100 12 112
					7 12 12 48 12 81 0 52 4 65 38 113 80 114 115 154 142 166 33 14 35 24 46 173
					8 115 7 129 -8 300 -13 138 -15 301 -10 725 3 228 -10 458 -28 480 -4 5 -11
					33 -15 60 -4 28 -13 64 -20 80 -7 17 -24 55 -38 87 -14 31 -43 78 -64 106 -22
					27 -46 72 -53 98 -15 57 -26 73 -56 84 -15 6 -26 26 -38 71 -10 34 -14 64 -9
					68 17 12 63 117 63 143 0 15 6 33 13 40 8 7 18 27 24 43 5 17 12 28 14 25 6
					-7 49 33 49 47 0 40 -111 168 -137 158 -7 -2 -13 0 -13 6 0 5 -10 13 -23 18
					-13 5 -37 21 -54 35 -16 14 -33 23 -36 19 -4 -3 -17 2 -31 10 -26 17 -113 20
					-123 4z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(11)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M3317 5700 c-14 -16 -32 -30 -39 -30 -22 0 -115 -102 -133 -145 -4
						-11 -7 -36 -6 -56 2 -26 -5 -44 -25 -68 l-27 -32 20 -22 c38 -41 88 -170 98
						-250 9 -68 -3 -91 -67 -135 -69 -46 -192 -109 -230 -116 -50 -9 -113 -54 -137
						-96 -14 -25 -21 -56 -21 -88 0 -28 -4 -54 -10 -57 -5 -3 -10 -17 -10 -30 0
						-13 -8 -43 -18 -67 -12 -27 -24 -95 -32 -184 -11 -127 -11 -149 5 -219 19 -87
						16 -152 -11 -238 l-18 -58 27 -79 c15 -44 31 -119 37 -167 5 -49 16 -113 25
						-143 8 -30 15 -71 15 -90 0 -49 13 -65 50 -62 29 3 30 2 30 -37 0 -22 5 -43
						11 -47 7 -4 8 -36 4 -99 -5 -58 -3 -126 5 -178 9 -67 9 -101 0 -159 -7 -40
						-14 -125 -15 -188 -5 -208 -15 -338 -30 -402 -12 -55 -24 -135 -40 -283 -3
						-22 -11 -96 -19 -165 -8 -69 -17 -205 -20 -304 -5 -175 -5 -178 17 -197 12
						-10 28 -18 35 -19 15 0 16 -44 1 -73 -14 -26 -13 -281 1 -307 18 -34 52 -39
						179 -30 133 10 151 36 117 170 -39 154 -39 225 -1 530 18 146 27 390 16 445
						-9 46 -7 56 26 130 19 44 45 127 58 185 56 260 117 510 137 568 40 114 48 87
						59 -208 4 -129 13 -287 19 -350 7 -70 8 -173 3 -265 -4 -82 -6 -156 -4 -163 1
						-7 -5 -51 -14 -97 -9 -47 -18 -166 -21 -271 -4 -163 -7 -191 -26 -227 -18 -35
						-21 -57 -19 -147 1 -57 5 -108 10 -113 4 -4 35 -13 68 -20 33 -7 78 -25 100
						-40 52 -36 127 -57 225 -62 103 -6 128 4 124 47 -5 53 -15 69 -101 153 -45 44
						-88 96 -97 115 -15 33 -14 42 7 145 12 61 30 198 39 305 30 332 63 581 111
						835 15 77 29 157 32 177 7 45 -3 88 -20 88 -6 0 -20 7 -31 15 -16 12 -17 18
						-6 47 7 18 16 51 20 73 5 22 14 58 20 80 26 91 27 89 -32 134 -238 184 -265
						233 -228 402 11 52 27 112 35 134 8 22 19 66 25 97 6 31 23 74 36 94 14 20 34
						73 45 117 11 44 33 106 49 136 15 31 31 71 35 90 8 40 59 144 90 186 61 82 97
						208 92 320 -4 104 -28 129 -203 221 -175 92 -199 116 -129 130 42 8 70 40 80
						91 4 21 14 51 22 67 11 21 13 44 9 86 -6 47 -3 63 17 101 14 26 27 71 31 111
						6 60 4 70 -19 105 -27 42 -27 42 -70 77 -29 24 -29 25 -7 26 12 0 22 4 22 9 0
						5 -19 9 -42 9 -55 0 -117 7 -125 16 -10 9 -63 7 -106 -5 -48 -13 -75 -4 -83
						27 -8 31 -17 30 -47 -6z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(12)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M6062 5202 c3 -10 0 -26 -7 -36 -12 -16 -92 -51 -142 -63 -42 -10
					-104 -91 -137 -182 -22 -61 -38 -89 -54 -96 -15 -6 -24 -21 -28 -45 -3 -19 -9
					-43 -15 -52 -5 -10 -9 -33 -9 -51 0 -18 -7 -58 -16 -87 -18 -61 -13 -129 11
					-166 24 -38 18 -59 -22 -78 -60 -29 -143 -87 -156 -110 -8 -14 -12 -82 -13
					-216 0 -128 -6 -234 -17 -310 -13 -88 -15 -127 -7 -168 8 -44 7 -59 -6 -84
					-14 -27 -15 -59 -11 -212 5 -155 9 -193 31 -269 32 -108 68 -194 112 -270 19
					-32 37 -70 41 -85 3 -15 15 -40 25 -57 10 -16 18 -38 18 -47 0 -9 13 -25 30
					-34 16 -10 32 -24 34 -31 3 -7 1 -93 -4 -191 -6 -97 -8 -220 -5 -272 10 -175
					14 -342 20 -755 11 -878 17 -1005 48 -1052 4 -7 3 -22 -4 -34 -14 -28 -7 -88
					16 -123 17 -25 20 -26 121 -26 117 0 124 4 124 80 0 29 13 68 40 123 42 86 42
					86 41 266 -1 40 6 105 14 145 8 39 17 88 20 109 4 21 15 63 26 94 28 83 40
					159 49 333 11 192 33 403 59 580 12 74 21 152 21 173 0 73 47 26 60 -59 4 -33
					19 -102 33 -154 13 -52 28 -117 31 -145 4 -27 16 -84 27 -125 11 -41 27 -111
					34 -156 8 -44 26 -105 39 -135 14 -30 27 -76 31 -104 11 -82 68 -244 100 -285
					12 -15 43 -182 43 -230 0 -14 2 -65 5 -115 6 -100 -5 -173 -32 -204 -10 -12
					-24 -41 -31 -66 -10 -37 -10 -52 2 -83 l14 -37 122 -3 121 -3 26 50 c18 37 25
					66 25 111 0 56 2 61 26 70 14 6 38 10 53 10 31 0 61 27 61 56 0 10 10 34 21
					53 20 33 20 40 9 130 -6 53 -18 114 -26 136 -8 23 -14 50 -14 61 0 11 -17 78
					-39 149 -21 72 -44 157 -52 190 -7 33 -16 65 -21 71 -4 6 -12 71 -17 145 -9
					129 -11 138 -61 259 -110 267 -103 240 -104 395 -1 77 3 286 8 465 9 332 8
					344 -30 500 -27 114 -31 133 -54 235 -12 55 -38 138 -57 185 -19 47 -46 111
					-59 144 -14 32 -22 61 -18 65 4 3 9 -1 12 -9 3 -8 22 -31 42 -50 21 -19 54
					-64 75 -98 24 -41 53 -75 83 -95 25 -18 72 -56 105 -84 40 -36 71 -54 95 -58
					21 -3 69 -21 107 -40 47 -22 88 -35 125 -37 l55 -3 22 60 c38 104 29 147 -46
					210 -19 17 -51 46 -71 66 -19 20 -56 48 -81 62 -53 30 -99 80 -99 107 0 24
					-56 77 -89 83 -20 4 -33 17 -46 44 -10 21 -33 63 -51 94 -56 94 -90 214 -108
					392 -20 189 -35 237 -77 237 -15 0 -34 7 -41 16 -7 9 -32 37 -56 61 -25 26
					-41 51 -38 59 3 9 -2 14 -14 14 -29 0 -33 1 -54 16 -16 11 -19 28 -21 126 -1
					62 -6 122 -12 133 -6 11 -11 58 -12 105 -2 96 -23 155 -73 207 -31 32 -49 39
					-118 48 -19 2 -40 11 -46 20 -9 12 -13 13 -16 3 -3 -9 -15 -2 -34 20 -32 36
					-49 41 -42 14z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(13)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M3139 6282 c-10 -2 -19 -11 -22 -21 -2 -11 -22 -23 -51 -31 -26 -7
					-46 -19 -46 -26 0 -26 -1 -29 -26 -61 -49 -64 -59 -102 -59 -215 1 -117 13
					-155 52 -164 35 -9 53 -42 53 -96 0 -71 -24 -112 -94 -157 -56 -35 -199 -85
					-306 -106 -19 -3 -42 -8 -51 -11 -17 -5 -33 -49 -44 -124 -9 -54 -39 -174 -54
					-213 -6 -16 -11 -39 -11 -53 0 -13 -11 -70 -24 -127 -61 -261 -64 -549 -6
					-657 7 -14 9 -40 5 -71 -7 -46 8 -141 25 -164 4 -5 15 -31 24 -56 13 -33 28
					-53 56 -70 24 -15 41 -34 45 -51 4 -15 12 -31 19 -37 8 -6 13 -48 15 -120 7
					-283 22 -603 31 -666 8 -50 8 -133 -1 -295 -11 -214 -8 -589 7 -772 7 -77 7
					-78 40 -90 19 -7 34 -18 34 -25 0 -7 4 -13 9 -13 4 0 11 33 15 73 7 88 23 197
					41 297 20 107 38 367 30 421 -4 27 -2 71 6 105 6 33 11 97 9 144 -2 47 -3 147
					-3 223 0 83 -4 137 -10 137 -6 0 -7 17 -4 41 5 38 3 42 -23 52 -18 7 -35 24
					-44 46 -22 49 -56 187 -56 226 0 17 -9 74 -20 125 -11 51 -20 95 -20 96 0 1
					-8 10 -17 20 -16 16 -16 21 9 93 24 70 25 82 16 151 -18 141 -20 292 -5 334
					12 36 12 41 -5 58 -17 17 -17 20 -3 35 26 26 33 54 25 111 -3 29 -3 55 0 57 4
					3 13 25 20 50 8 28 16 42 20 34 5 -8 10 -5 14 9 12 37 93 99 146 112 26 6 81
					30 122 53 41 23 87 49 102 57 37 19 136 118 136 135 0 8 -16 30 -35 50 -18 20
					-42 58 -51 83 -9 26 -23 63 -32 82 -9 19 -15 51 -14 70 1 19 4 66 6 103 2 55
					7 73 28 95 14 15 34 41 46 58 12 17 37 39 57 48 19 9 51 36 71 61 33 39 40 43
					69 38 48 -8 59 19 52 121 -8 114 -19 186 -27 186 -5 0 -13 12 -19 28 -20 49
					-33 66 -98 123 -13 10 -23 16 -23 13 0 -3 -17 4 -37 16 -24 14 -46 20 -64 16
					-15 -3 -29 -2 -31 2 -2 4 -10 6 -19 4z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(14)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M5787 5654 c-2 -3 -22 -5 -43 -6 -32 -1 -47 -9 -80 -43 -23 -23 -45
					-51 -48 -63 -4 -12 -14 -22 -23 -22 -8 0 -13 -6 -10 -12 2 -7 -2 -28 -9 -47
					-8 -18 -14 -44 -14 -58 0 -13 -6 -26 -14 -29 -11 -4 -13 -12 -6 -28 5 -15 2
					-34 -11 -62 l-18 -39 -2 30 c-2 41 -16 -59 -22 -160 -3 -44 -5 -84 -6 -88 -1
					-5 -9 -6 -20 -2 -13 4 -24 -4 -43 -32 -13 -21 -27 -46 -31 -55 -5 -14 -13 -16
					-38 -11 -28 5 -31 4 -26 -11 4 -11 1 -19 -10 -23 -9 -4 -19 -1 -21 5 -2 7 -16
					-6 -32 -29 -25 -38 -27 -45 -15 -73 11 -26 10 -45 -7 -121 -19 -89 -31 -200
					-42 -403 -3 -57 -9 -114 -12 -128 -6 -24 16 -71 42 -87 7 -4 15 -26 19 -50 9
					-55 28 -90 58 -110 23 -15 26 -14 49 11 37 39 48 65 48 114 0 46 36 128 70
					160 11 10 22 29 26 42 6 27 74 76 173 125 34 18 64 38 67 46 3 7 -2 25 -12 40
					-15 23 -16 36 -6 113 6 48 14 119 17 157 3 42 19 108 40 167 43 123 50 136 93
					175 63 56 123 82 204 89 l74 7 -13 89 c-8 49 -20 106 -28 125 -8 20 -15 50
					-16 67 -1 44 -11 65 -39 79 -16 8 -26 23 -28 41 -2 18 -11 30 -22 33 -11 3
					-22 13 -25 22 -7 21 -63 53 -71 40 -4 -7 -12 -5 -22 4 -15 14 -84 21 -95 11z"
									/>
								</g>
							</g>
						</svg>
					</SvgTablet>

					<SvgPhone>
						<svg
							version="1.0"
							xmlns="http://www.w3.org/2000/svg"
							width="80vw"
							viewBox="0 0 1911.000000 1147.000000"
							preserveAspectRatio="xMidYMid meet"
						>
							<g>
								{/*vivek */}
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(1)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M4240 6318 c-30 -12 -60 -28 -66 -37 -7 -9 -26 -32 -43 -51 -38 -44
							-43 -68 -41 -214 1 -79 9 -105 50 -156 15 -18 22 -47 26 -95 6 -66 4 -72 -26
							-118 -38 -56 -51 -64 -205 -116 -65 -22 -129 -50 -142 -63 -32 -30 -46 -87
							-48 -198 0 -68 -7 -116 -24 -170 -24 -78 -48 -110 -85 -110 -11 0 -27 -4 -34
							-9 -28 -17 13 -52 131 -113 143 -73 215 -128 224 -169 3 -16 8 -29 12 -29 16
							1 43 56 51 106 14 84 17 90 71 129 28 20 62 56 77 80 22 37 41 52 112 87 74
							36 96 42 168 46 75 4 82 6 83 26 3 61 -9 126 -25 126 -20 0 -43 31 -60 80 -21
							61 -13 180 17 237 19 37 20 47 9 76 -24 65 -4 177 32 177 8 0 26 14 41 32 27
							32 27 32 22 163 -7 180 -11 194 -80 254 -45 38 -53 41 -107 41 -32 0 -64 2
							-72 4 -7 2 -38 -5 -68 -16z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(2)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M7621 6336 c-7 -8 -32 -25 -56 -36 -75 -36 -120 -90 -140 -167 -18
						-68 -50 -234 -64 -328 -4 -27 -12 -60 -19 -72 -7 -12 -11 -32 -10 -45 1 -13
						-8 -52 -20 -88 -40 -123 -46 -165 -33 -249 21 -132 10 -119 104 -124 111 -7
						143 -15 191 -49 117 -83 115 -82 142 -69 14 6 33 11 44 11 15 0 20 10 25 58 4
						31 13 98 20 147 8 50 16 135 20 189 6 112 24 163 76 218 53 56 83 72 108 56
						32 -20 43 0 26 47 -8 22 -14 59 -15 82 0 23 -4 44 -9 47 -4 3 -11 28 -15 55
						-7 55 -23 91 -74 171 -36 56 -78 99 -98 100 -6 0 -19 9 -29 20 -10 11 -26 20
						-36 20 -10 0 -21 5 -24 10 -4 6 -10 8 -14 6 -19 -12 -75 -16 -81 -6 -5 7 -11
						6 -19 -4z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(3)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M4495 5137 c-16 -5 -59 -13 -95 -18 -156 -21 -220 -44 -220 -79 0
						-12 -5 -17 -13 -14 -19 7 -33 -14 -41 -58 -5 -29 -16 -43 -49 -65 -31 -21 -46
						-41 -57 -73 -9 -25 -26 -67 -38 -93 -12 -27 -22 -57 -22 -67 0 -11 -9 -24 -19
						-30 -23 -12 -43 -67 -43 -116 0 -19 -3 -34 -8 -34 -4 0 -10 -34 -12 -77 -4
						-51 -10 -80 -20 -87 -18 -13 -38 -50 -44 -79 -2 -12 -13 -29 -23 -37 -16 -12
						-20 -27 -19 -70 1 -42 -7 -71 -31 -123 -17 -38 -38 -95 -46 -128 -9 -32 -24
						-73 -34 -91 -10 -18 -26 -60 -36 -93 -9 -33 -23 -78 -30 -100 -22 -66 -45
						-194 -45 -251 0 -34 7 -69 19 -92 19 -37 129 -142 199 -191 19 -13 42 -37 52
						-53 19 -32 16 -52 -31 -207 -17 -57 -18 -67 -5 -86 8 -12 28 -27 44 -34 l29
						-12 6 -135 7 -135 65 -132 c62 -125 66 -140 81 -252 9 -66 22 -142 30 -170 15
						-60 30 -316 24 -435 -2 -47 -4 -114 -3 -150 1 -36 2 -123 3 -195 1 -71 7 -161
						15 -200 8 -38 19 -112 25 -164 9 -67 24 -123 52 -192 l39 -98 -26 -47 c-14
						-25 -37 -63 -51 -83 -32 -47 -31 -82 4 -119 52 -55 151 -70 220 -33 34 18 41
						42 40 127 -2 108 10 205 35 294 19 69 21 104 24 435 2 198 4 374 5 390 2 47
						26 214 38 275 7 33 9 83 5 125 -18 188 -6 596 16 537 11 -28 14 -146 5 -161
						-4 -6 -3 -18 4 -26 14 -17 20 -102 26 -364 2 -112 8 -209 13 -215 11 -13 32
						-113 38 -176 2 -25 8 -182 13 -350 8 -261 12 -318 31 -395 29 -119 36 -251 15
						-312 -24 -71 -21 -110 13 -144 25 -25 37 -29 83 -29 78 0 139 33 156 84 12 36
						10 43 -16 95 -19 37 -31 79 -36 123 -6 59 -2 81 29 183 19 64 35 129 35 144 0
						21 12 38 51 71 60 51 60 57 9 188 l-33 81 22 58 c13 35 21 78 20 108 -1 28 4
						102 10 165 32 338 33 360 11 451 -15 64 -20 118 -20 226 0 157 15 248 54 332
						24 52 25 63 26 250 0 108 4 233 9 278 9 86 16 101 93 194 21 26 38 53 38 62 0
						8 7 20 16 28 26 21 54 86 54 123 0 46 -17 163 -36 246 -8 36 -14 75 -14 86 0
						36 -40 156 -64 194 -13 19 -33 64 -45 100 -12 36 -26 74 -31 85 -4 11 -14 36
						-21 55 -28 75 -62 109 -185 190 -35 23 -96 110 -105 150 -16 72 -14 168 4 188
						22 25 22 58 -3 92 -17 24 -18 30 -5 46 7 11 10 23 5 29 -11 13 -60 159 -60
						179 0 22 -47 51 -115 70 -33 10 -64 23 -70 30 -7 9 -18 10 -40 3z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(4)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M4635 5797 c-11 -7 -26 -20 -33 -29 -7 -10 -22 -18 -32 -18 -22 0
							-100 -77 -100 -99 0 -8 -8 -23 -17 -33 -11 -12 -17 -37 -17 -78 -1 -33 -5 -60
							-9 -60 -11 0 0 -101 12 -113 6 -6 11 -20 11 -31 0 -25 28 -61 54 -70 10 -3 17
							-9 14 -11 -2 -3 1 -31 7 -62 l12 -58 73 -27 c40 -15 80 -34 89 -43 14 -13 32
							-69 58 -175 3 -14 10 -28 15 -31 5 -3 4 -17 -3 -32 -10 -21 -8 -30 8 -54 34
							-48 34 -51 11 -72 -17 -16 -22 -33 -23 -73 -2 -81 15 -180 35 -208 10 -14 64
							-56 121 -95 64 -43 111 -83 123 -103 10 -18 36 -81 56 -140 38 -109 58 -132
							79 -93 6 11 11 48 11 83 -1 35 4 99 9 143 5 44 10 132 10 195 1 85 8 146 28
							236 14 66 23 131 20 145 -7 27 -122 96 -247 149 -77 32 -144 86 -161 129 -10
							24 -8 28 40 116 21 39 74 81 97 77 17 -3 40 60 43 118 1 30 4 63 7 72 3 10 -4
							38 -15 64 -12 26 -21 55 -21 65 0 10 -9 23 -20 29 -11 6 -20 15 -20 21 0 6 -9
							23 -20 39 -11 15 -17 30 -14 33 14 14 -72 76 -131 94 -50 16 -134 16 -160 0z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(5)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M7327 5272 c-13 -12 -17 -13 -17 -3 0 11 -3 11 -15 1 -8 -7 -15 -18
					-15 -26 0 -7 -19 -26 -42 -43 -24 -16 -57 -44 -75 -63 -35 -37 -103 -135 -103
					-149 0 -5 -11 -16 -24 -24 -13 -9 -32 -37 -41 -63 -19 -49 -73 -153 -84 -162
					-18 -14 -51 -96 -51 -127 0 -19 -4 -32 -9 -29 -22 14 -38 -6 -36 -45 1 -23 -3
					-43 -11 -50 -8 -6 -14 -8 -14 -5 0 13 -25 -18 -37 -45 -6 -15 -9 -36 -6 -48 4
					-15 1 -21 -10 -21 -9 0 -21 -9 -27 -20 -6 -11 -18 -20 -26 -20 -8 0 -13 -4
					-10 -9 3 -5 2 -29 -3 -54 -6 -32 -4 -54 5 -77 8 -18 14 -41 14 -51 0 -11 4
					-19 9 -19 9 0 17 -50 25 -147 2 -24 8 -48 14 -56 6 -7 20 -46 31 -87 12 -41
					25 -76 31 -78 15 -5 70 -107 70 -131 0 -11 4 -21 10 -21 5 0 7 -7 4 -15 -4
					-10 0 -15 11 -15 9 0 13 -4 10 -10 -3 -5 -2 -10 4 -10 5 0 14 7 19 16 15 26
					47 1 73 -56 12 -27 26 -50 30 -50 4 0 10 -8 13 -17 3 -10 25 -26 48 -36 25
					-10 83 -56 136 -106 89 -84 92 -89 92 -131 0 -43 -23 -121 -43 -148 -6 -7 -18
					-10 -31 -6 -12 4 -35 8 -52 10 -16 1 -52 16 -79 33 -30 19 -62 31 -82 31 -40
					0 -80 19 -108 50 -11 12 -40 36 -65 53 -69 46 -119 97 -145 147 -13 25 -42 63
					-64 85 -35 34 -41 45 -41 81 0 25 6 49 16 58 18 18 15 32 -26 118 -32 68 -37
					62 -44 -44 -4 -67 13 -177 31 -200 22 -29 105 -261 124 -349 11 -52 27 -116
					34 -144 50 -171 56 -230 55 -510 0 -148 -4 -342 -8 -430 -11 -241 -7 -262 78
					-460 73 -169 73 -171 82 -290 6 -81 21 -164 44 -255 20 -74 46 -176 59 -227
					27 -105 50 -324 40 -389 -7 -48 4 -57 25 -20 9 17 10 39 5 68 -11 66 -13 535
					-3 608 6 36 11 138 13 228 4 187 12 270 32 327 31 89 42 229 48 624 2 189 8
					318 14 324 14 14 38 -8 50 -46 15 -50 21 -314 10 -438 -15 -176 -12 -303 11
					-449 11 -74 25 -169 31 -210 5 -41 15 -95 23 -120 22 -71 35 -138 46 -235 6
					-49 15 -108 21 -130 5 -22 16 -144 24 -271 7 -127 19 -261 26 -298 9 -53 9
					-81 -1 -126 -7 -33 -10 -66 -7 -74 3 -8 -7 -33 -23 -55 -16 -22 -32 -51 -36
					-65 l-6 -26 201 0 c173 0 201 2 201 15 0 8 23 37 52 64 l52 49 -2 66 c-2 48
					-9 78 -27 109 -13 25 -27 70 -31 105 -5 51 -3 70 15 111 33 77 26 157 -22 271
					-8 19 -18 73 -22 120 -13 161 -34 279 -74 402 -89 274 -91 342 -16 667 19 80
					46 186 60 236 66 226 86 403 61 536 -14 75 -21 162 -29 349 -2 37 1 45 18 48
					30 6 75 52 96 99 11 23 31 50 46 61 l27 20 3 178 c1 99 6 258 10 354 l8 175
					24 0 c19 0 26 8 39 48 12 38 19 47 36 45 16 -2 21 4 28 35 8 39 -11 147 -31
					171 -6 7 -20 42 -31 78 -18 58 -41 95 -59 92 -13 -2 -41 22 -41 36 0 8 -25 40
					-56 71 -52 53 -94 131 -94 177 0 7 -7 20 -15 28 -8 9 -15 26 -15 38 0 13 -9
					31 -20 41 -11 10 -23 34 -26 54 -4 20 -14 47 -23 60 -9 14 -16 29 -17 35 0 6
					-2 16 -2 22 -1 7 -11 14 -23 17 -15 4 -23 0 -26 -14 -8 -31 -22 -22 -28 19
					-10 58 -24 88 -48 100 -34 16 -61 25 -69 24 -4 -1 -10 9 -14 23 -3 14 -9 23
					-13 20 -4 -2 -27 2 -52 9 -83 24 -105 26 -122 8z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(6)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M8060 5798 c0 -29 -22 -38 -51 -21 -27 16 -29 15 -69 -11 -26 -17
							-40 -33 -36 -41 3 -8 -6 -22 -20 -33 -31 -22 -54 -100 -54 -182 0 -67 -38
							-373 -48 -381 -4 -4 -24 -12 -44 -18 l-37 -12 19 -21 c11 -12 27 -46 36 -77 8
							-31 22 -66 30 -78 39 -61 58 -102 65 -138 16 -86 35 -117 132 -210 79 -76 97
							-99 106 -136 7 -24 22 -66 35 -94 37 -79 50 -148 36 -190 -10 -31 -20 -40 -42
							-36 -4 0 -10 -9 -13 -22 -18 -64 -27 -77 -55 -77 l-28 0 -7 -303 c-4 -167 -4
							-317 -1 -335 9 -36 -6 -68 -34 -77 -13 -4 -31 -28 -44 -58 -25 -56 -57 -90
							-94 -102 l-24 -7 7 -146 c3 -81 13 -178 22 -217 30 -139 8 -342 -62 -575 -14
							-47 -43 -168 -66 -270 -60 -275 -58 -359 17 -585 18 -55 37 -118 43 -140 14
							-55 41 -249 41 -298 0 -64 20 -117 47 -123 61 -16 101 30 88 100 -5 26 -2 46
							12 73 33 62 35 105 22 377 -12 260 -12 261 10 304 19 38 32 94 62 272 5 30 12
							159 15 285 6 197 4 243 -11 315 -24 121 -24 532 1 507 2 -2 12 -31 22 -63 15
							-51 17 -96 14 -334 -2 -171 1 -322 9 -400 9 -82 13 -307 13 -660 1 -486 3
							-539 19 -582 15 -40 16 -53 5 -91 -9 -35 -9 -51 2 -76 15 -37 31 -42 127 -38
							93 3 122 27 133 109 4 30 22 85 40 123 32 68 33 75 37 220 2 83 7 202 12 265
							5 66 5 248 0 425 -4 171 -9 452 -9 625 -1 277 2 328 19 419 11 57 23 106 26
							109 3 3 14 7 25 9 22 3 64 -52 56 -73 -4 -10 78 -104 91 -104 10 0 44 45 59
							78 11 25 13 42 5 65 -16 53 -25 127 -26 217 -1 100 -12 259 -25 385 -5 50 -12
							108 -14 130 -2 22 -9 63 -14 90 -5 28 -10 122 -11 210 -2 88 -8 185 -15 215
							-9 41 -10 78 -3 143 7 60 6 95 -1 110 -18 38 -50 180 -56 248 -4 43 -46 118
							-87 156 -21 20 -41 44 -44 54 -3 9 -19 22 -34 27 -27 9 -31 18 -50 104 -11 51
							-23 117 -27 145 -4 29 -10 51 -14 48 -4 -2 -10 34 -13 81 -3 57 -10 91 -20
							102 -10 11 -15 33 -14 59 2 49 -19 93 -45 93 -12 0 -18 7 -18 21 0 30 -20 61
							-35 55 -7 -2 -21 6 -29 19 -19 28 -38 40 -83 50 -25 5 -33 3 -33 -7z m262
							-410 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9 -15 0 -24z"
									/>
									<path
										d="M7648 5080 c5 -36 10 -38 21 -7 5 14 4 25 -3 29 -19 13 -23 9 -18
							-22z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(7)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M6694 6480 c-6 -6 -30 -12 -55 -14 -126 -11 -154 -30 -198 -136 -13
						-30 -30 -65 -39 -78 l-15 -23 -14 21 c-12 17 -21 20 -44 15 -22 -5 -29 -3 -29
						8 0 30 -28 41 -78 31 -42 -8 -47 -12 -50 -39 -2 -16 -8 -35 -13 -42 -27 -32
						-11 -205 21 -237 6 -6 10 -29 10 -52 0 -23 7 -58 15 -78 14 -34 14 -39 -1 -66
						-17 -28 -17 -30 7 -62 14 -18 35 -50 48 -70 l22 -37 -70 -36 c-46 -23 -75 -45
						-84 -62 -24 -46 -29 -132 -13 -203 8 -36 17 -86 21 -112 8 -59 26 -78 73 -78
						47 0 94 -28 131 -80 34 -47 51 -114 51 -203 0 -62 12 -98 27 -83 4 4 10 64 13
						132 6 141 1 132 98 165 34 11 76 32 92 45 38 33 68 114 75 211 12 147 69 348
						116 413 17 22 20 37 16 67 -6 35 -4 40 23 55 19 11 33 28 36 46 6 29 35 64 84
						101 21 16 25 28 26 74 0 31 5 62 10 69 6 7 11 37 12 67 2 46 -3 61 -33 106
						-19 29 -52 63 -72 75 -21 12 -42 29 -46 36 -5 10 -16 12 -29 8 -11 -3 -23 -2
						-27 4 -4 7 -95 52 -105 52 -1 0 -7 -5 -12 -10z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(8)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M5387 6374 c-4 -4 -18 -9 -30 -9 -12 -1 -48 -13 -79 -27 -68 -32 -76
					-51 -58 -141 7 -34 14 -127 15 -207 2 -153 10 -184 53 -224 41 -36 35 -106
					-13 -146 -17 -14 -84 -44 -183 -83 -61 -23 -67 -33 -77 -130 -6 -55 -14 -75
					-44 -114 -20 -27 -44 -54 -54 -61 -9 -7 -24 -28 -32 -45 -9 -18 -22 -43 -30
					-57 -8 -14 -15 -35 -15 -47 0 -34 64 -99 123 -125 29 -12 108 -51 176 -85 130
					-66 134 -66 147 -16 6 23 78 63 114 63 36 0 81 30 95 63 26 62 44 153 46 227
					1 41 8 111 17 155 8 44 15 95 16 113 1 17 4 32 8 32 3 0 29 23 56 52 67 69 74
					105 33 156 -26 30 -31 45 -31 89 0 42 5 57 26 80 14 15 41 56 61 90 30 55 34
					71 34 131 0 66 -23 152 -41 152 -4 0 -21 13 -36 28 -16 16 -56 37 -89 47 -33
					11 -65 23 -72 28 -17 12 -126 21 -136 11z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(9)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M6916 5814 c-56 -20 -142 -115 -162 -178 -8 -28 -19 -60 -24 -71 -10
					-25 -21 -70 -25 -110 -22 -215 -52 -317 -105 -355 -14 -9 -49 -25 -79 -35 -30
					-10 -66 -29 -79 -42 -22 -22 -23 -29 -17 -92 7 -69 -9 -220 -27 -255 -6 -12
					-7 -16 0 -12 5 3 16 -2 25 -12 12 -14 13 -27 5 -75 -10 -57 -10 -59 18 -86 45
					-44 159 -134 193 -153 19 -10 37 -32 47 -57 14 -35 19 -39 35 -31 27 15 69 80
					69 107 0 13 4 31 10 41 20 36 63 150 91 242 40 130 57 168 100 218 21 24 61
					91 90 148 58 116 98 159 195 208 49 25 54 30 54 61 0 19 -5 46 -12 62 -6 15
					-13 51 -15 78 -6 100 -8 110 -23 108 -16 -3 -30 32 -50 123 -12 52 -51 97
					-132 152 -53 36 -113 42 -182 16z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(10)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M9283 6567 c-3 -5 -11 -6 -18 -3 -13 5 -89 -28 -117 -51 -7 -6 -29
					-10 -48 -8 -19 1 -40 -4 -48 -11 -7 -8 -24 -14 -36 -14 -26 0 -84 -61 -90 -94
					-3 -12 -10 -47 -16 -79 -11 -55 -11 -59 12 -81 12 -12 30 -25 39 -28 19 -7 31
					-57 23 -97 -5 -25 -2 -32 25 -46 40 -20 51 -49 51 -134 0 -84 12 -97 84 -94
					55 2 72 -6 101 -51 l19 -29 -26 -21 c-27 -22 -27 -36 -2 -138 5 -22 -4 -43
					-48 -110 -90 -137 -151 -244 -200 -348 -17 -36 -51 -94 -77 -130 -72 -100 -74
					-108 -163 -455 l-63 -250 0 -190 c0 -177 16 -326 36 -349 4 -4 71 -9 148 -11
					78 -1 144 -4 146 -7 3 -3 1 -17 -5 -32 -5 -15 -10 -42 -10 -59 -1 -18 -7 -43
					-15 -57 -21 -36 -18 -73 8 -138 16 -38 23 -70 20 -97 -3 -22 -1 -50 4 -63 5
					-14 4 -73 -3 -145 -19 -190 -17 -277 10 -396 26 -114 44 -297 56 -586 5 -120
					15 -210 28 -270 51 -224 51 -218 5 -236 -16 -7 -77 -12 -136 -13 -103 -1 -110
					-3 -127 -26 -26 -34 -30 -54 -16 -81 16 -32 144 -66 301 -79 66 -6 163 -16
					215 -22 158 -19 279 -22 296 -7 26 22 10 179 -27 256 -16 33 -34 87 -40 120
					-5 32 -16 78 -24 103 -15 50 -28 448 -16 490 7 23 21 12 230 -195 123 -121
					250 -251 282 -288 66 -76 67 -81 23 -169 -13 -26 -24 -58 -24 -72 0 -15 -21
					-48 -61 -91 -57 -63 -60 -70 -54 -104 15 -78 46 -80 185 -10 117 59 221 151
					259 231 33 69 58 97 80 91 21 -5 51 20 134 114 33 38 45 59 42 75 -5 23 -12
					31 -101 103 -27 22 -75 66 -107 98 -98 98 -142 127 -192 127 l-43 0 -109 132
					c-60 73 -130 160 -155 193 -26 33 -74 89 -108 125 -34 36 -77 88 -97 117 -19
					29 -56 68 -82 88 -69 54 -74 70 -42 119 23 36 26 51 27 131 0 50 5 100 12 112
					7 12 12 48 12 81 0 52 4 65 38 113 80 114 115 154 142 166 33 14 35 24 46 173
					8 115 7 129 -8 300 -13 138 -15 301 -10 725 3 228 -10 458 -28 480 -4 5 -11
					33 -15 60 -4 28 -13 64 -20 80 -7 17 -24 55 -38 87 -14 31 -43 78 -64 106 -22
					27 -46 72 -53 98 -15 57 -26 73 -56 84 -15 6 -26 26 -38 71 -10 34 -14 64 -9
					68 17 12 63 117 63 143 0 15 6 33 13 40 8 7 18 27 24 43 5 17 12 28 14 25 6
					-7 49 33 49 47 0 40 -111 168 -137 158 -7 -2 -13 0 -13 6 0 5 -10 13 -23 18
					-13 5 -37 21 -54 35 -16 14 -33 23 -36 19 -4 -3 -17 2 -31 10 -26 17 -113 20
					-123 4z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(11)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M3317 5700 c-14 -16 -32 -30 -39 -30 -22 0 -115 -102 -133 -145 -4
						-11 -7 -36 -6 -56 2 -26 -5 -44 -25 -68 l-27 -32 20 -22 c38 -41 88 -170 98
						-250 9 -68 -3 -91 -67 -135 -69 -46 -192 -109 -230 -116 -50 -9 -113 -54 -137
						-96 -14 -25 -21 -56 -21 -88 0 -28 -4 -54 -10 -57 -5 -3 -10 -17 -10 -30 0
						-13 -8 -43 -18 -67 -12 -27 -24 -95 -32 -184 -11 -127 -11 -149 5 -219 19 -87
						16 -152 -11 -238 l-18 -58 27 -79 c15 -44 31 -119 37 -167 5 -49 16 -113 25
						-143 8 -30 15 -71 15 -90 0 -49 13 -65 50 -62 29 3 30 2 30 -37 0 -22 5 -43
						11 -47 7 -4 8 -36 4 -99 -5 -58 -3 -126 5 -178 9 -67 9 -101 0 -159 -7 -40
						-14 -125 -15 -188 -5 -208 -15 -338 -30 -402 -12 -55 -24 -135 -40 -283 -3
						-22 -11 -96 -19 -165 -8 -69 -17 -205 -20 -304 -5 -175 -5 -178 17 -197 12
						-10 28 -18 35 -19 15 0 16 -44 1 -73 -14 -26 -13 -281 1 -307 18 -34 52 -39
						179 -30 133 10 151 36 117 170 -39 154 -39 225 -1 530 18 146 27 390 16 445
						-9 46 -7 56 26 130 19 44 45 127 58 185 56 260 117 510 137 568 40 114 48 87
						59 -208 4 -129 13 -287 19 -350 7 -70 8 -173 3 -265 -4 -82 -6 -156 -4 -163 1
						-7 -5 -51 -14 -97 -9 -47 -18 -166 -21 -271 -4 -163 -7 -191 -26 -227 -18 -35
						-21 -57 -19 -147 1 -57 5 -108 10 -113 4 -4 35 -13 68 -20 33 -7 78 -25 100
						-40 52 -36 127 -57 225 -62 103 -6 128 4 124 47 -5 53 -15 69 -101 153 -45 44
						-88 96 -97 115 -15 33 -14 42 7 145 12 61 30 198 39 305 30 332 63 581 111
						835 15 77 29 157 32 177 7 45 -3 88 -20 88 -6 0 -20 7 -31 15 -16 12 -17 18
						-6 47 7 18 16 51 20 73 5 22 14 58 20 80 26 91 27 89 -32 134 -238 184 -265
						233 -228 402 11 52 27 112 35 134 8 22 19 66 25 97 6 31 23 74 36 94 14 20 34
						73 45 117 11 44 33 106 49 136 15 31 31 71 35 90 8 40 59 144 90 186 61 82 97
						208 92 320 -4 104 -28 129 -203 221 -175 92 -199 116 -129 130 42 8 70 40 80
						91 4 21 14 51 22 67 11 21 13 44 9 86 -6 47 -3 63 17 101 14 26 27 71 31 111
						6 60 4 70 -19 105 -27 42 -27 42 -70 77 -29 24 -29 25 -7 26 12 0 22 4 22 9 0
						5 -19 9 -42 9 -55 0 -117 7 -125 16 -10 9 -63 7 -106 -5 -48 -13 -75 -4 -83
						27 -8 31 -17 30 -47 -6z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(12)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M6062 5202 c3 -10 0 -26 -7 -36 -12 -16 -92 -51 -142 -63 -42 -10
					-104 -91 -137 -182 -22 -61 -38 -89 -54 -96 -15 -6 -24 -21 -28 -45 -3 -19 -9
					-43 -15 -52 -5 -10 -9 -33 -9 -51 0 -18 -7 -58 -16 -87 -18 -61 -13 -129 11
					-166 24 -38 18 -59 -22 -78 -60 -29 -143 -87 -156 -110 -8 -14 -12 -82 -13
					-216 0 -128 -6 -234 -17 -310 -13 -88 -15 -127 -7 -168 8 -44 7 -59 -6 -84
					-14 -27 -15 -59 -11 -212 5 -155 9 -193 31 -269 32 -108 68 -194 112 -270 19
					-32 37 -70 41 -85 3 -15 15 -40 25 -57 10 -16 18 -38 18 -47 0 -9 13 -25 30
					-34 16 -10 32 -24 34 -31 3 -7 1 -93 -4 -191 -6 -97 -8 -220 -5 -272 10 -175
					14 -342 20 -755 11 -878 17 -1005 48 -1052 4 -7 3 -22 -4 -34 -14 -28 -7 -88
					16 -123 17 -25 20 -26 121 -26 117 0 124 4 124 80 0 29 13 68 40 123 42 86 42
					86 41 266 -1 40 6 105 14 145 8 39 17 88 20 109 4 21 15 63 26 94 28 83 40
					159 49 333 11 192 33 403 59 580 12 74 21 152 21 173 0 73 47 26 60 -59 4 -33
					19 -102 33 -154 13 -52 28 -117 31 -145 4 -27 16 -84 27 -125 11 -41 27 -111
					34 -156 8 -44 26 -105 39 -135 14 -30 27 -76 31 -104 11 -82 68 -244 100 -285
					12 -15 43 -182 43 -230 0 -14 2 -65 5 -115 6 -100 -5 -173 -32 -204 -10 -12
					-24 -41 -31 -66 -10 -37 -10 -52 2 -83 l14 -37 122 -3 121 -3 26 50 c18 37 25
					66 25 111 0 56 2 61 26 70 14 6 38 10 53 10 31 0 61 27 61 56 0 10 10 34 21
					53 20 33 20 40 9 130 -6 53 -18 114 -26 136 -8 23 -14 50 -14 61 0 11 -17 78
					-39 149 -21 72 -44 157 -52 190 -7 33 -16 65 -21 71 -4 6 -12 71 -17 145 -9
					129 -11 138 -61 259 -110 267 -103 240 -104 395 -1 77 3 286 8 465 9 332 8
					344 -30 500 -27 114 -31 133 -54 235 -12 55 -38 138 -57 185 -19 47 -46 111
					-59 144 -14 32 -22 61 -18 65 4 3 9 -1 12 -9 3 -8 22 -31 42 -50 21 -19 54
					-64 75 -98 24 -41 53 -75 83 -95 25 -18 72 -56 105 -84 40 -36 71 -54 95 -58
					21 -3 69 -21 107 -40 47 -22 88 -35 125 -37 l55 -3 22 60 c38 104 29 147 -46
					210 -19 17 -51 46 -71 66 -19 20 -56 48 -81 62 -53 30 -99 80 -99 107 0 24
					-56 77 -89 83 -20 4 -33 17 -46 44 -10 21 -33 63 -51 94 -56 94 -90 214 -108
					392 -20 189 -35 237 -77 237 -15 0 -34 7 -41 16 -7 9 -32 37 -56 61 -25 26
					-41 51 -38 59 3 9 -2 14 -14 14 -29 0 -33 1 -54 16 -16 11 -19 28 -21 126 -1
					62 -6 122 -12 133 -6 11 -11 58 -12 105 -2 96 -23 155 -73 207 -31 32 -49 39
					-118 48 -19 2 -40 11 -46 20 -9 12 -13 13 -16 3 -3 -9 -15 -2 -34 20 -32 36
					-49 41 -42 14z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(13)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M3139 6282 c-10 -2 -19 -11 -22 -21 -2 -11 -22 -23 -51 -31 -26 -7
					-46 -19 -46 -26 0 -26 -1 -29 -26 -61 -49 -64 -59 -102 -59 -215 1 -117 13
					-155 52 -164 35 -9 53 -42 53 -96 0 -71 -24 -112 -94 -157 -56 -35 -199 -85
					-306 -106 -19 -3 -42 -8 -51 -11 -17 -5 -33 -49 -44 -124 -9 -54 -39 -174 -54
					-213 -6 -16 -11 -39 -11 -53 0 -13 -11 -70 -24 -127 -61 -261 -64 -549 -6
					-657 7 -14 9 -40 5 -71 -7 -46 8 -141 25 -164 4 -5 15 -31 24 -56 13 -33 28
					-53 56 -70 24 -15 41 -34 45 -51 4 -15 12 -31 19 -37 8 -6 13 -48 15 -120 7
					-283 22 -603 31 -666 8 -50 8 -133 -1 -295 -11 -214 -8 -589 7 -772 7 -77 7
					-78 40 -90 19 -7 34 -18 34 -25 0 -7 4 -13 9 -13 4 0 11 33 15 73 7 88 23 197
					41 297 20 107 38 367 30 421 -4 27 -2 71 6 105 6 33 11 97 9 144 -2 47 -3 147
					-3 223 0 83 -4 137 -10 137 -6 0 -7 17 -4 41 5 38 3 42 -23 52 -18 7 -35 24
					-44 46 -22 49 -56 187 -56 226 0 17 -9 74 -20 125 -11 51 -20 95 -20 96 0 1
					-8 10 -17 20 -16 16 -16 21 9 93 24 70 25 82 16 151 -18 141 -20 292 -5 334
					12 36 12 41 -5 58 -17 17 -17 20 -3 35 26 26 33 54 25 111 -3 29 -3 55 0 57 4
					3 13 25 20 50 8 28 16 42 20 34 5 -8 10 -5 14 9 12 37 93 99 146 112 26 6 81
					30 122 53 41 23 87 49 102 57 37 19 136 118 136 135 0 8 -16 30 -35 50 -18 20
					-42 58 -51 83 -9 26 -23 63 -32 82 -9 19 -15 51 -14 70 1 19 4 66 6 103 2 55
					7 73 28 95 14 15 34 41 46 58 12 17 37 39 57 48 19 9 51 36 71 61 33 39 40 43
					69 38 48 -8 59 19 52 121 -8 114 -19 186 -27 186 -5 0 -13 12 -19 28 -20 49
					-33 66 -98 123 -13 10 -23 16 -23 13 0 -3 -17 4 -37 16 -24 14 -46 20 -64 16
					-15 -3 -29 -2 -31 2 -2 4 -10 6 -19 4z"
									/>
								</g>
								<g
									fillOpacity={0}
									transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)"
									fill="#000000"
									stroke="none"
									onMouseOver={() => setHoverID(14)}
									onMouseOut={() => setHoverID(0)}
								>
									<path
										d="M5787 5654 c-2 -3 -22 -5 -43 -6 -32 -1 -47 -9 -80 -43 -23 -23 -45
					-51 -48 -63 -4 -12 -14 -22 -23 -22 -8 0 -13 -6 -10 -12 2 -7 -2 -28 -9 -47
					-8 -18 -14 -44 -14 -58 0 -13 -6 -26 -14 -29 -11 -4 -13 -12 -6 -28 5 -15 2
					-34 -11 -62 l-18 -39 -2 30 c-2 41 -16 -59 -22 -160 -3 -44 -5 -84 -6 -88 -1
					-5 -9 -6 -20 -2 -13 4 -24 -4 -43 -32 -13 -21 -27 -46 -31 -55 -5 -14 -13 -16
					-38 -11 -28 5 -31 4 -26 -11 4 -11 1 -19 -10 -23 -9 -4 -19 -1 -21 5 -2 7 -16
					-6 -32 -29 -25 -38 -27 -45 -15 -73 11 -26 10 -45 -7 -121 -19 -89 -31 -200
					-42 -403 -3 -57 -9 -114 -12 -128 -6 -24 16 -71 42 -87 7 -4 15 -26 19 -50 9
					-55 28 -90 58 -110 23 -15 26 -14 49 11 37 39 48 65 48 114 0 46 36 128 70
					160 11 10 22 29 26 42 6 27 74 76 173 125 34 18 64 38 67 46 3 7 -2 25 -12 40
					-15 23 -16 36 -6 113 6 48 14 119 17 157 3 42 19 108 40 167 43 123 50 136 93
					175 63 56 123 82 204 89 l74 7 -13 89 c-8 49 -20 106 -28 125 -8 20 -15 50
					-16 67 -1 44 -11 65 -39 79 -16 8 -26 23 -28 41 -2 18 -11 30 -22 33 -11 3
					-22 13 -25 22 -7 21 -63 53 -71 40 -4 -7 -12 -5 -22 4 -15 14 -84 21 -95 11z"
									/>
								</g>
							</g>
						</svg>
					</SvgPhone>
				</HoverContainer>
			</TeamContainer>

			<TextContainer style={{ display: hoverID === 0 ? "flex" : "none" }}>
				<TitleText style={{ textAlign: "center" }}>
					Meet The Members
					Meet The Members
				</TitleText>
				<SmallSpacer />
				<BodyText>
					Meet the Fall 2023 Members. We're so happy these
					amazing members are with us! To be part of the next MEG
					class consider coming to our recruiting events and applying.
				</BodyText>
				<SmallSpacer />
				<BodyText>
					<b>
						______________________________
					</b>
				</BodyText>
			</TextContainer>
			
			<TextContainer style={{ display: hoverID !== 0 ? "flex" : "none" }}>
			<TitleText style={{ textAlign: "center" }}>
					Meet The Members
				</TitleText>
				<SmallSpacer />
				<BodyText>
					Meet the Fall 2023 Class. We're so happy these
					amazing members are with us! To be part of the next MEG
					class consider coming to our recruiting events and applying.
				</BodyText>
				<SmallSpacer />
			</TextContainer>
		</SectionContainer>
	);
}

export default TeamSection;
